import React from "react";
import { all, takeEvery, put, call } from 'redux-saga/effects';
import {toast} from "react-toastify";

import {LOGIN_USER, FORGOT_PASSWORD, RESET_PASSWORD, LOGOUT_USER} from '../action/types';
import {
    loginUserSuccess,
    loginUserFailure,
    forgotPasswordSuccess,
    forgotPasswordFailure,
    resetPasswordSuccess,
    resetPasswordFailure,
    logOutUserSuccess,
    logOutUserFailure
} from '../action';
import API from '../../utils/api';
import {ErrorToast, setToken, setUser, SuccessToast} from '../../utils/helper';

function* loginUserRequest(action) {
    try {
        const res = yield API.post('login', {
            email: action.payload.email,
            password: action.payload.password
        });
        if(res.data.meta.code === 1){
            yield put(loginUserSuccess(res.data.data));
            yield call(setUser, JSON.stringify(res.data.data));
            yield call(setToken, res.data.meta.token);
            yield call(action.payload.callBack);
        } else if(res.data.meta.code === 0) {
            yield put(loginUserFailure(res.data));
            yield toast.error(<ErrorToast msg={res.data.meta.message}/>);
        }
    }
    catch (e) {
        yield put(loginUserFailure(e));
        yield toast.error(<ErrorToast msg='Something went wrong'/>);
    }
}

function* forgotPasswordRequest(action) {
    try{
        const bodyFormData = new FormData();
        bodyFormData.append('email', action.payload.email);
        const res = yield API.post('forgot-password',bodyFormData);
        if(res.data && res.data.meta && res.data.meta.code === 1) {
            yield put(forgotPasswordSuccess());
            yield toast.success(<SuccessToast msg='We have e-mailed your password reset link!'/>);
        } else {
            yield put(forgotPasswordFailure());
            yield toast.error(<ErrorToast msg={res.data.message}/>);
        }
    } catch (e) {
        yield put(forgotPasswordFailure());
        yield toast.error(<ErrorToast msg='Something went wrong'/>);
    }
}

function* resetPasswordRequest(action) {
    try{
        const bodyFormData = new FormData();
        bodyFormData.append('otp', action.payload.code);
        bodyFormData.append('password', action.payload.password);
        const res = yield API.post('reset-password',bodyFormData);
        if(res.data.code === 1) {
            yield put(resetPasswordSuccess());
            yield toast.success(<SuccessToast msg={res.data.message}/>);
            yield call(action.payload.redirectUrl);
        }else if(res.data.code === 0) {
            yield put(resetPasswordFailure());
            yield toast.error(<ErrorToast msg={res.data.message}/>);
        }
    } catch (e) {
        yield put(resetPasswordFailure());
        yield toast.error(<ErrorToast msg='Something went wrong.'/>);
    }
}

function* logOutUserRequest(action){
    try {
        const res = yield API.get('logout')
        if(res.data.code === 1){
            yield put(logOutUserSuccess());
            yield call(action.payload)
            yield toast.success(<SuccessToast msg={res.data.message}/>);
        } else {
            yield put(logOutUserFailure());
            yield toast.error(<ErrorToast msg={res.data.message}/>);
        }
    } catch (e) {
        yield put(logOutUserFailure());
        yield toast.error(<ErrorToast msg='Something went wrong.'/>);
    }
}

export function* watchLoginUserAPI() {
    yield takeEvery(LOGIN_USER, loginUserRequest);
}

export function* watchForgotPasswordAPI() {
    yield takeEvery(FORGOT_PASSWORD, forgotPasswordRequest)
}

export function* watchResetPasswordAPI() {
    yield takeEvery(RESET_PASSWORD, resetPasswordRequest)
}

export function* watchLogoutUserAPI() {
    yield takeEvery(LOGOUT_USER, logOutUserRequest)
}

export default function* rootSaga() {
    yield all([
        watchLoginUserAPI(),
        watchForgotPasswordAPI(),
        watchResetPasswordAPI(),
        watchLogoutUserAPI()
    ]);
}
