import {
    CREATE_OR_EDIT_ATHLETE,
    CREATE_OR_EDIT_ATHLETE_SUCCESS,
    CREATE_OR_EDIT_ATHLETE_FAILURE,

    GET_ATHLETES_LIST,
    GET_ATHLETES_LIST_SUCCESS,
    GET_ATHLETES_LIST_FAILURE,

    DELETE_ATHLETE,
    DELETE_ATHLETE_SUCCESS,
    DELETE_ATHLETE_FAILURE,

    GET_ATHLETE_DETAIL,
    GET_ATHLETE_DETAIL_SUCCESS,
    GET_ATHLETE_DETAIL_FAILURE,
    RESET_ATHLETE_DETAIL,

    GET_ATHLETE_DASHBOARD_DATA,
    GET_ATHLETE_DASHBOARD_DATA_SUCCESS,
    GET_ATHLETE_DASHBOARD_DATA_FAILURE,

    SEND_MESSAGE,
    SEND_MESSAGE_SUCCESS,
    SEND_MESSAGE_FAILURE
} from '../action/types';

const INIT_STATE = {
    loading: false,
    athletesList: null,
    athleteDetail: {},
    athleteDashboardData: {}
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case CREATE_OR_EDIT_ATHLETE:
            return { ...state, loading: true };
        case CREATE_OR_EDIT_ATHLETE_SUCCESS:
            return { ...state, loading: false };
        case CREATE_OR_EDIT_ATHLETE_FAILURE:
            return { ...state, loading: false };

        case GET_ATHLETES_LIST:
            return { ...state, loading: true };
        case GET_ATHLETES_LIST_SUCCESS:
            return { ...state, loading: false, athletesList: action.payload };
        case GET_ATHLETES_LIST_FAILURE:
            return { ...state, loading: false, athletesList: action.payload };

        case DELETE_ATHLETE:
            return { ...state, loading: true };
        case DELETE_ATHLETE_SUCCESS:
            return { ...state, loading: false };
        case DELETE_ATHLETE_FAILURE:
            return { ...state, loading: false };

        case GET_ATHLETE_DETAIL:
            return { ...state, loading: true };
        case GET_ATHLETE_DETAIL_SUCCESS:
            return { ...state, loading: false, athleteDetail: action.payload };
        case GET_ATHLETE_DETAIL_FAILURE:
            return { ...state, loading: false, athleteDetail: action.payload };
        case RESET_ATHLETE_DETAIL:
            return { ...state, athleteDetail: {} };

        case GET_ATHLETE_DASHBOARD_DATA:
            return {...state, loading: true};
        case GET_ATHLETE_DASHBOARD_DATA_SUCCESS:
            return {...state, loading: false, athleteDashboardData: action.payload};
        case GET_ATHLETE_DASHBOARD_DATA_FAILURE:
            return {...state, loading: false, athleteDashboardData: action.payload};

        case SEND_MESSAGE:
            return { ...state, loading: true };
        case SEND_MESSAGE_SUCCESS:
            return { ...state, loading: false };
        case SEND_MESSAGE_FAILURE:
            return { ...state, loading: false };

        default:
            return state;

    }
};