import React from "react";
import {all, call, put, takeEvery} from 'redux-saga/effects';
import {toast} from "react-toastify";

import {
    deleteLEGALFailure,
    deleteLEGALSuccess,
    getLegalListFailure,
    getLegalListSuccess,
    uploadLegalFailure,
    uploadLegalSuccess
} from "../action";
import {DELETE_LEGAL, GET_LEGAL_DETAIL, GET_LEGAL_LIST, UPLOAD_LEGAL} from "../action/types";
import API from "../../utils/api";
import {
    getLegalDetailFailure,
    getLegalDetailSuccess
} from "../action";
import {ErrorToast, SuccessToast} from "../../utils/helper";

function* uploadLegalFile(action) {
    const bodyFormData = new FormData();
    bodyFormData.append('athlete', action.payload.req.id);
    action.payload.req.files.forEach((item) => {
        bodyFormData.append('file[]', item.file);
    });
    try {
        const res = yield API.post('legal/file', bodyFormData);
        if (res.data.meta.code === 1) {
            yield put(uploadLegalSuccess(res.data));
            yield toast.success(<SuccessToast msg='Files uploaded!'/>);
            yield call(action.payload.callback);
        } else {
            yield toast.error(<ErrorToast msg={res.data.meta.message}/>);
        }
    } catch (e) {
        yield put(uploadLegalFailure(e));
        yield toast.error(<ErrorToast msg='Something went wrong.'/>);

    }
}

function* getLegalListRequest(action) {
    try {
        const res = yield API.get('legal?athlete_id=' + action.payload.athlete_id);
        yield put(getLegalListSuccess(res.data.data))
    } catch (e) {
        yield put(getLegalListFailure(e));
    }
}

function* getLegalDetailRequest(action) {
    try{
        const res = yield API.get(`legal/${action.payload}/detail`);
        if (res.data.meta.code === 1) {
            yield put(getLegalDetailSuccess(res.data.data));
        }else{
            yield toast.error(<ErrorToast msg={res.data.meta.message}/>);
        }
    }catch (e) {
        yield put(getLegalDetailFailure(e));
    }
}

function* DeleteLegalRequest(action) {
    try{
        const res = yield API.delete(`legal/${action.payload.id}`);
        if (res.data.meta.code === 1) {
            yield put(deleteLEGALSuccess(res.data.data));
            yield call(action.payload.callBack);
            yield toast.success(<SuccessToast msg={res.data.meta.message}/>);
        } else if (res.data.mete.code === 0) {
            yield put(deleteLEGALFailure());
            yield toast.error(<ErrorToast msg={res.data.meta.message}/>);
        }
    }catch (e) {
        yield put(deleteLEGALFailure());
        yield toast.error(<ErrorToast msg='Something went wrong.'/>);
    }
}

export function* watchUploadLegal() {
    yield takeEvery(UPLOAD_LEGAL, uploadLegalFile);
}

export function* watchLegalListAPI() {
    yield takeEvery(GET_LEGAL_LIST, getLegalListRequest);
}

export function* watchLegalDetailAPI() {
    yield takeEvery(GET_LEGAL_DETAIL, getLegalDetailRequest);
}

export function* watchLegalDeleteAPI() {
    yield takeEvery(DELETE_LEGAL, DeleteLegalRequest);
}

export default function* rootSaga() {
    yield all([
        watchUploadLegal(),
        watchLegalListAPI(),
        watchLegalDetailAPI(),
        watchLegalDeleteAPI()
    ]);
}
