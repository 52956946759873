import React, {useLayoutEffect, useState} from 'react';
import {ExclamationTriangleFill, CheckCircleFill} from 'react-bootstrap-icons';

export const setToken = (token) => localStorage.setItem('token', token);

export const getToken = () => localStorage.getItem('token');

export const removeToken = () => localStorage.removeItem('token');

export const setFolderLocal = (folder) =>
	localStorage.setItem('folder', folder);

export const getFolderLocal = () => localStorage.getItem('folder');

export const removeFolder = () => localStorage.removeItem('folder');

export const setUser = (user) => localStorage.setItem('user', user);

export const getUser = () => localStorage.getItem('user');

export const removeUser = () => localStorage.removeItem('user');

export const apiUrl = process.env.REACT_APP_API_ENDPOINT;
export const mediaUrl = process.env.REACT_APP_MEDIA_URL;
export const imagesUrl = process.env.REACT_APP_IMAGES_URL;

export const orderList = [
	{name: 'Date created', value: 'created_at'},
	{name: 'Date modified', value: 'updated_at'},
	{name: 'Name', value: 'title'},
];

export const bytesToMegaBytes = (bytes) => bytes / (1024 * 1024);

export const time_ago = (time) => {
	switch (typeof time) {
		case 'number':
			break;
		case 'string':
			time = +new Date(time);
			break;
		case 'object':
			if (time.constructor === Date) time = time.getTime();
			break;
		default:
			time = +new Date();
	}
	const time_formats = [
		[60, 'seconds', 1], // 60
		[120, '1 minute ago', '1 minute from now'], // 60*2
		[3600, 'minutes', 60], // 60*60, 60
		[7200, '1 hour ago', '1 hour from now'], // 60*60*2
		[86400, 'hours', 3600], // 60*60*24, 60*60
		[172800, 'Yesterday', 'Tomorrow'], // 60*60*24*2
		[604800, 'days', 86400], // 60*60*24*7, 60*60*24
		[1209600, 'Last week', 'Next week'], // 60*60*24*7*4*2
		[2419200, 'weeks', 604800], // 60*60*24*7*4, 60*60*24*7
		[4838400, 'Last month', 'Next month'], // 60*60*24*7*4*2
		[29030400, 'months', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
		[58060800, 'Last year', 'Next year'], // 60*60*24*7*4*12*2
		[2903040000, 'years', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
		[5806080000, 'Last century', 'Next century'], // 60*60*24*7*4*12*100*2
		[58060800000, 'centuries', 2903040000], // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
	];
	let seconds = (+new Date() - time) / 1000,
		token = 'ago',
		list_choice = 1;

	if (seconds === 0) {
		return 'Just now';
	}
	if (seconds < 0) {
		seconds = Math.abs(seconds);
		token = 'from now';
		list_choice = 2;
	}
	let i = 0,
		format;
	// eslint-disable-next-line
	while ((format = time_formats[i++]))
		if (seconds < format[0]) {
			if (typeof format[2] == 'string') return format[list_choice];
			else
				return Math.floor(seconds / format[2]) + ' ' + format[1] + ' ' + token;
		}
	return time;
};

export const useWindowSize = () => {
	const [size, setSize] = useState([0, 0]);
	useLayoutEffect(() => {
		function updateSize() {
			setSize([window.innerWidth, window.innerHeight]);
		}
		window.addEventListener('resize', updateSize);
		updateSize();
		return () => window.removeEventListener('resize', updateSize);
	}, []);
	return size;
};

export const ErrorToast = ({msg}) => (
	<div>
		<ExclamationTriangleFill
			size={16}
			className='bi bi-exclamation-triangle mb-1 mr-1'
		/>
		{msg}
	</div>
);

export const SuccessToast = ({msg}) => (
	<div>
		<CheckCircleFill size={16} className='bi bi-check-circle mb-1 mr-1' />
		{msg}
	</div>
);
