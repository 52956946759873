import {
    CREATE_OR_EDIT_EXTERNAL_USER,
    CREATE_OR_EDIT_EXTERNAL_USER_SUCCESS,
    CREATE_OR_EDIT_EXTERNAL_USER_FAILURE,
    GET_EXTERNAL_USER_LIST,
    GET_EXTERNAL_USER_LIST_SUCCESS,
    GET_EXTERNAL_USER_LIST_FAILURE,
    DELETE_EXTERNAL_USER,
    DELETE_EXTERNAL_USER_SUCCESS,
    DELETE_EXTERNAL_USER_FAILURE,
    GET_EXTERNAL_USER_DETAIL,
    GET_EXTERNAL_USER_DETAIL_SUCCESS,
    GET_EXTERNAL_USER_DETAIL_FAILURE,
    RESET_EXTERNAL_USER_DETAIL,
} from './types';

/*  Listing Actions */
export const getUsersList = () => ({
    type: GET_EXTERNAL_USER_LIST
});

export const getUserListSuccess = (payload) => ({
    type: GET_EXTERNAL_USER_LIST_SUCCESS,
    payload
});

export const getUserListFailure = payload => ({
    type: GET_EXTERNAL_USER_LIST_FAILURE,
    payload
});

/*  Delete Actions */
export const deleteUser = payload => ({
    type: DELETE_EXTERNAL_USER,
    payload
});

export const deleteUserSuccess = () => ({
    type: DELETE_EXTERNAL_USER_SUCCESS
});

export const deleteUserFailure = () => ({
    type: DELETE_EXTERNAL_USER_FAILURE
});

/*  Add or Edit Actions */
export const createOrEditExternalUser = payload => ({
    type: CREATE_OR_EDIT_EXTERNAL_USER,
    payload
});

export const createOrEditExternalUserSuccess = () => ({
    type: CREATE_OR_EDIT_EXTERNAL_USER_SUCCESS
});

export const createOrEditExternalUserFailure= () => ({
    type: CREATE_OR_EDIT_EXTERNAL_USER_FAILURE
});

export const getExternalUserDetail = (payload) => ({
    type: GET_EXTERNAL_USER_DETAIL,
    payload
});

export const getExternalUserDetailSuccess = payload => ({
    type: GET_EXTERNAL_USER_DETAIL_SUCCESS,
    payload
});

export const getExternalUserDetailFailure = payload => ({
    type: GET_EXTERNAL_USER_DETAIL_FAILURE,
    payload
});

export const resetExternalUserDetail = () => ({
    type: RESET_EXTERNAL_USER_DETAIL
});

