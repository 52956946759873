import {

    GET_MARKETING_LIST,
    GET_MARKETING_LIST_SUCCESS,
    GET_MARKETING_LIST_FAILURE,

    CREATE_OR_EDIT_MARKETING,
    CREATE_OR_EDIT_MARKETING_SUCCESS,
    CREATE_OR_EDIT_MARKETING_FAILURE,

    DELETE_MARKETING,
    DELETE_MARKETING_SUCCESS,
    DELETE_MARKETING_FAILURE,

    GET_MARKETING_DETAIL,
    GET_MARKETING_DETAIL_SUCCESS,
    GET_MARKETING_DETAIL_FAILURE,
    RESET_MARKETING_DETAIL,

} from './types';

export const getMarketingList = (payload) => ({
    type: GET_MARKETING_LIST,
    payload
});

export const getMarketingListSuccess = (payload) => ({
    type: GET_MARKETING_LIST_SUCCESS,
    payload
});

export const getMarketingListFailure = payload => ({
    type: GET_MARKETING_LIST_FAILURE,
    payload
});
/* ======= for delete, create or edit ====== */

export const createOrEditMarketing = payload => ({
    type: CREATE_OR_EDIT_MARKETING,
    payload
});

export const createOrEditMarketingSuccess = () => ({
    type: CREATE_OR_EDIT_MARKETING_SUCCESS
});

export const createOrEditMarketingFailure= () => ({
    type: CREATE_OR_EDIT_MARKETING_FAILURE
});

export const deleteMarketing = payload => ({
    type: DELETE_MARKETING,
    payload
});

export const deleteMarketingSuccess = () => ({
    type: DELETE_MARKETING_SUCCESS
});

export const deleteMarketingFailure = () => ({
    type: DELETE_MARKETING_FAILURE
});

export const getMarketingDetail = (payload) => ({
    type: GET_MARKETING_DETAIL,
    payload
});

export const getMarketingDetailSuccess = payload => ({
    type: GET_MARKETING_DETAIL_SUCCESS,
    payload
});

export const getMarketingDetailFailure = payload => ({
    type: GET_MARKETING_DETAIL_FAILURE,
    payload
});

export const resetMarketingDetail = () => ({
    type: RESET_MARKETING_DETAIL
});