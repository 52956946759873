import React, { memo } from 'react';
import { render } from 'react-dom';
import {Provider} from 'react-redux';
import { ToastContainer, Slide } from 'react-toastify';

import store from './redux/store';
import Routes from "./routes";
import './libcss';

const MainApp = memo(() => {
    return(
        <Provider store={store()}>
            <Routes/>
            <ToastContainer
                autoClose={5000}
                draggable={false}
                transition={Slide}
                closeButton={true}
                position='top-center'
                toastClassName='toast-notification'
            />
        </Provider>
    );
});

const rootElement = document.getElementById('root');

render(<MainApp/>, rootElement);
