import React, { Suspense, lazy, memo } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import { Loader } from '../components/CommonComponents';
import ErrorBoundary from '../components/ErrorBoundary';
import PrivateRoute from "./PrivateRoute";

const Login = lazy(() => import('../container/login'));
const ForgotPassword = lazy(() => import('../container/forgot-password'));
const Main = lazy(() => import('../container/main'));
const ResetPassword = lazy(() => import('../container/resetPassword'));

const Routes = memo(() => {
    return (
        <Router>
            <ErrorBoundary>
                <Suspense fallback={<Loader/>}>
                    <Switch>
                        <Route exact path="/login" name="Login Page" component={Login}/>
                        <Route
                            exact
                            path="/forgot-password"
                            name="Forgot Password Page"
                            component={ForgotPassword}
                        />
                        <Route
                            exact
                            path="/password/reset"
                            name="Reset Password Page"
                            component={ResetPassword}
                        />
                        <PrivateRoute path="/" name="Main Page" component={Main}/>
                    </Switch>
                </Suspense>
            </ErrorBoundary>
        </Router>
    );
});

export default Routes;