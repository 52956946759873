import {
    DELETE_LEGAL,
    DELETE_LEGAL_FAILURE,
    DELETE_LEGAL_SUCCESS,
    GET_LEGAL_DETAIL,
    GET_LEGAL_DETAIL_FAILURE,
    GET_LEGAL_DETAIL_SUCCESS,
    GET_LEGAL_LIST,
    GET_LEGAL_LIST_FAILURE,
    GET_LEGAL_LIST_SUCCESS,
    RESET_LEGAL_DETAIL,
    UPLOAD_LEGAL,
    UPLOAD_LEGAL_FAILURE,
    UPLOAD_LEGAL_SUCCESS
} from "../action/types";

const INIT_STATE = {
    loading: false,
    legalList: {},
    legalDetail: {}
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case UPLOAD_LEGAL:
            return {...state, loading: true};
        case UPLOAD_LEGAL_SUCCESS:
            return {...state, loading: false};
        case UPLOAD_LEGAL_FAILURE:
            return {...state, loading: false};
        case GET_LEGAL_LIST:
            return {...state, loading: true};
        case GET_LEGAL_LIST_SUCCESS:
            return {...state, loading: false, legalList: action.payload};
        case GET_LEGAL_LIST_FAILURE:
            return {...state, loading: false, legalList: action.payload};
        case DELETE_LEGAL:
            return {...state, loading: true};
        case DELETE_LEGAL_SUCCESS:
            return {...state, loading: false};
        case DELETE_LEGAL_FAILURE:
            return {...state, loading: false};
        case GET_LEGAL_DETAIL:
            return {...state, loading: true};
        case GET_LEGAL_DETAIL_SUCCESS:
            return {...state, loading: false, legalDetail: action.payload};
        case GET_LEGAL_DETAIL_FAILURE:
            return {...state, loading: false, legalDetail: action.payload};
        case RESET_LEGAL_DETAIL:
            return {...state, legalDetail: {}};
        default:
            return state;
    }
};
