import { combineReducers } from 'redux';

import Login from './loginReducer';
import Goals from './goalsReducer';
import Athlete from './athletesReducer';
import ExternalUser from  './externalUserReducer'
import Highlights from './highlightReducer';
import Marketing from './marketingReducer';
import Branding from './brandingReducer';
import Legal from './legalReducer';
import Visionboard from './visionboardReducer';
import Calendar from "./calendarReducer";

const appReducer = combineReducers({
    Login,
    Goals,
    Athlete,
    ExternalUser,
    Highlights,
    Marketing,
    Branding,
    Legal,
    Visionboard,
    Calendar
});

const reducers = (state, action) => {
    return appReducer(state, action);
};

export default reducers;