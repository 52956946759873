import {
    CREATE_OR_EDIT_HIGHLIGHTS,
    CREATE_OR_EDIT_HIGHLIGHTS_FAILURE,
    CREATE_OR_EDIT_HIGHLIGHTS_SUCCESS,
    DELETE_HIGHLIGHTS,
    DELETE_HIGHLIGHTS_FAILURE,
    DELETE_HIGHLIGHTS_SUCCESS,
    GET_HIGHLIGHTS_DETAIL,
    GET_HIGHLIGHTS_DETAIL_FAILURE,
    GET_HIGHLIGHTS_DETAIL_SUCCESS,
    GET_HIGHLIGHTS_LIST,
    GET_HIGHLIGHTS_LIST_FAILURE,
    GET_HIGHLIGHTS_LIST_SUCCESS,
    RESET_HIGHLIGHTS_DETAIL,
} from "./types";

export const createOrEditHighlights = payload => ({
    type: CREATE_OR_EDIT_HIGHLIGHTS,
    payload
});

export const createOrEditHighlightsSuccess = () => ({
    type: CREATE_OR_EDIT_HIGHLIGHTS_SUCCESS
});

export const createOrEditHighlightsFailure= () => ({
    type: CREATE_OR_EDIT_HIGHLIGHTS_FAILURE
});

export const getHighlightsList = (payload) => ({
    type: GET_HIGHLIGHTS_LIST,
    payload
})

export const getHighlightsListSuccess = payload => ({
    type: GET_HIGHLIGHTS_LIST_SUCCESS,
    payload
})

export const getHighlightsListFailure = payload => ({
    type: GET_HIGHLIGHTS_LIST_FAILURE,
    payload
})

export const getHIGHLIGHTSDetail = (payload) => ({
    type: GET_HIGHLIGHTS_DETAIL,
    payload
});

export const getHIGHLIGHTSDetailSuccess = payload => ({
    type: GET_HIGHLIGHTS_DETAIL_SUCCESS,
    payload
});

export const getHIGHLIGHTSDetailFailure = payload => ({
    type: GET_HIGHLIGHTS_DETAIL_FAILURE,
    payload
});

export const resetHIGHLIGHTSDetail = () => ({
    type: RESET_HIGHLIGHTS_DETAIL
});


export const deleteHIGHLIGHTS = payload => ({
    type: DELETE_HIGHLIGHTS,
    payload
});

export const deleteHIGHLIGHTSSuccess = () => ({
    type: DELETE_HIGHLIGHTS_SUCCESS
});

export const deleteHIGHLIGHTSFailure = () => ({
    type: DELETE_HIGHLIGHTS_FAILURE
});
