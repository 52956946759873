import {

    GET_BRANDING_LIST,
    GET_BRANDING_LIST_SUCCESS,
    GET_BRANDING_LIST_FAILURE,

    ADD_BRANDING_FOLDER,
    ADD_BRANDING_FOLDER_SUCCESS,
    ADD_BRANDING_FOLDER_FAILURE,

    DELETE_FOLDER,
    DELETE_FOLDER_SUCCESS,
    DELETE_FOLDER_FAILURE,

    DELETE_FILE,
    DELETE_FILE_SUCCESS,
    DELETE_FILE_FAILURE,

    CREATE_NEW_MEDIA,
    CREATE_NEW_MEDIA_SUCCESS,
    CREATE_NEW_MEDIA_FAILURE

} from './types';

export const getBrandingList = (payload) => ({
    type: GET_BRANDING_LIST,
    payload
});

export const getBrandingListSuccess = (payload) => ({
    type: GET_BRANDING_LIST_SUCCESS,
    payload
});

export const getBrandingListFailure = payload => ({
    type: GET_BRANDING_LIST_FAILURE,
    payload
});

export const addBrandingFolder = payload => ({
    type: ADD_BRANDING_FOLDER,
    payload
});

export const addBrandingFolderSuccess = () => ({
   type: ADD_BRANDING_FOLDER_SUCCESS
});

export const addBrandingFolderFailure = () => ({
    type: ADD_BRANDING_FOLDER_FAILURE
})

export const deleteFolder = (payload) => ({
   type: DELETE_FOLDER,
   payload
});

export const deleteFolderSuccess = () => ({
    type: DELETE_FOLDER_SUCCESS
});

export const deleteFolderFailure = () => ({
    type: DELETE_FOLDER_FAILURE
});

export const deleteFile = payload => ({
   type: DELETE_FILE,
   payload
});

export const deleteFileSuccess = () => ({
   type: DELETE_FILE_SUCCESS
});

export const deleteFileFailure = () => ({
    type: DELETE_FILE_FAILURE
});

export const createNewMedia = (payload) => ({
   type: CREATE_NEW_MEDIA,
   payload
});

export const createNewMediaSuccess = () => ({
   type: CREATE_NEW_MEDIA_SUCCESS
});

export const createNewMediaFailure = () => ({
   type: CREATE_NEW_MEDIA_FAILURE
});