import React from 'react';
import PropTypes from 'prop-types';

const InputWithLabel = ({label, name, type, placeholder, className, value, onChange, errorMsg, required, maxLength, disabled}) => {
    return (
        <div className='input-with-label'>
            <label htmlFor={name} className='f-14'>
                {label}
            </label>
            {required && <span className='text-danger ml-1'>*</span>}
            <input
                id={name}
                type={type}
                placeholder={placeholder}
                name={name}
                className={className}
                value={value}
                onChange={onChange}
                maxLength={maxLength}
                disabled={disabled}
            />
            <div className="invalid-feedback">
                {errorMsg}
            </div>
        </div>
    );
};

InputWithLabel.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    errorMsg: PropTypes.string,
    required: PropTypes.bool,
    maxLength: PropTypes.number,
    disabled: PropTypes.bool
};

export default InputWithLabel;