import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILURE,
    USER_DATA_REMOVE,

    FORGOT_PASSWORD,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAILURE,

    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,

    LOGOUT_USER,
    LOGOUT_USER_SUCCESS,
    LOGOUT_USER_FAILURE
} from './types';

export const loginUser = payload => ({
    type: LOGIN_USER,
    payload
});

export const loginUserSuccess = (payload) => ({
    type: LOGIN_USER_SUCCESS,
    payload
});

export const loginUserFailure = (payload) => ({
    type: LOGIN_USER_FAILURE,
    payload
});

export const userDataRemove = () => ({
    type: USER_DATA_REMOVE
})

export const forgotPassword = payload => ({
    type: FORGOT_PASSWORD,
    payload
});

export const forgotPasswordSuccess = () => ({
   type: FORGOT_PASSWORD_SUCCESS
});

export const forgotPasswordFailure = () => ({
   type: FORGOT_PASSWORD_FAILURE
});

export const resetPassword = payload => ({
   type: RESET_PASSWORD,
   payload
});

export const resetPasswordSuccess = () => ({
    type: RESET_PASSWORD_SUCCESS
});

export const resetPasswordFailure = () => ({
   type: RESET_PASSWORD_FAILURE
});

export const logOutUser = (payload) => ({
    type: LOGOUT_USER,
    payload
});

export const logOutUserSuccess = () => ({
    type: LOGOUT_USER_SUCCESS
});

export const logOutUserFailure = () => ({
    type: LOGOUT_USER_FAILURE
});