import React, {Component} from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import {ArrowRepeat} from 'react-bootstrap-icons';

class DateRangePickerCom extends Component {
	state = {
		value: '',
	};

	handleApply = (event, picker) => {
		picker.element.val(
			picker.startDate.format('D MMM YYYY') +
				' - ' +
				picker.endDate.format('D MMM YYYY')
		);

		this.setState({
			value:
				picker.startDate.format('D MMM YYYY') +
				' - ' +
				picker.endDate.format('D MMM YYYY'),
		});
		this.props.setDate({
			start: picker.startDate.format('DD/MM/YYYY'),
			end: picker.endDate.format('DD/MM/YYYY'),
		});
		this.props.handleDateFilter({
			start: picker.startDate.format('DD/MM/YYYY'),
			end: picker.endDate.format('DD/MM/YYYY'),
		});
	};

	handleRefresh = () => {
		this.setState({
			value: '',
		});
		this.props.setDate(null);
		this.props.handleRefresh();
	};

	render() {
		return (
			<>
				<div>
					<DateRangePicker
						initialSettings={{
							autoUpdateInput: false,
							locale: {
								cancelLabel: 'Cancel',
							},
						}}
						onApply={this.handleApply}>
						<input
							type='text'
							className='form-control'
							onKeyDown={(e) => e.preventDefault()}
							value={this.state.value}
							placeholder='Select Date Range'
						/>
					</DateRangePicker>
				</div>
				{this.props.isDashboard && (
					<ArrowRepeat
						className='img-fluid ml-3 align-self-center cursor-pointer reset'
						size={24}
						alt='reset'
						onClick={() => this.handleRefresh()}
					/>
				)}
			</>
		);
	}
}

export default DateRangePickerCom;
