import React from 'react';
import PropTypes from 'prop-types';

import {getUser} from "../../utils/helper";

const Table = ({setDeleteOpen, setCreateOrEditGoalOpen, tableData, setGoalId, handleCheckEvent, setEditGoalId}) => {
    return (
        <>
            <div className="row bg-white justify-content-lg-end m-lg-4 m-1">
                <div className="col-12 pl-0 pr-0 overflow-auto">
                    <table className="table table-borderless mb-0">
                        <thead>
                            <tr className="trBorderBottom">
                                <th className="font-weight-bold f-24 pl-lg-4 pr-lg-4 light-dark font-weight-light" style={{verticalAlign:"middle", width:"460px", minWidth:"460px"}}>Goal</th>
                                <td className="f-18 pl-lg-4 pr-lg-4 light-dark" style={{verticalAlign:"middle", width:"209px", minWidth:"209px"}}>Start</td>
                                <td className="f-18 pl-lg-4 pr-lg-4 light-dark" style={{verticalAlign:"middle", width:"181px", minWidth:"181px"}}>Due</td>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            tableData && tableData.length > 0 ?
                                tableData.map((data, key) =>
                                    <tr key={key} className="trBorderBottom">
                                        <td className="f-18 pl-lg-4 pr-lg-4 light-dark" style={{verticalAlign:"middle"}}>
                                            <span className="d-flex align-items-center justify-content-between">
                                                {
                                                    JSON.parse(getUser()).is_admin ?
                                                    <span className="d-flex align-items-center">

                                                        <i
                                                            className={`fa ${data.completed ? 'fa-check-square-o mint-green-color' : 'fa-square-o light-dark'} cursor-pointer mr-3`}
                                                            onClick={() => handleCheckEvent(data)}
                                                        />

                                                        ({data.athlete})
                                                        {'\u00A0'}
                                                        <span
                                                            className="cursor-pointer"
                                                            onClick={() => {
                                                                setCreateOrEditGoalOpen(true);
                                                                setEditGoalId(data.id);
                                                            }}
                                                        >
                                                            {data.title}
                                                        </span>
                                                    </span>
                                                    :
                                                    <span className="d-flex align-items-center">

                                                        <i
                                                            className={`fa ${data.completed ? 'fa-check-square-o mint-green-color' : 'fa-square-o light-dark'} cursor-pointer mr-3`}
                                                            onClick={() => handleCheckEvent(data)}
                                                        />

                                                        {'\u00A0'}
                                                        <span
                                                            className="cursor-pointer"
                                                            onClick={() => {
                                                                setCreateOrEditGoalOpen(true);
                                                                setEditGoalId(data.id);
                                                            }}
                                                        >
                                                            {data.title}
                                                        </span>
                                                    </span>
                                                }
                                                <i className="fa fa-trash cursor-pointer"
                                                   onClick={() => {
                                                       setDeleteOpen(true);
                                                       setGoalId(data.id);
                                                   }}
                                                />
                                            </span>
                                        </td>
                                        <td className="f-18 pl-lg-4 pr-lg-4 light-dark" style={{verticalAlign:"middle"}}>{data.start_date_h}</td>
                                        <td className="f-18 pl-lg-4 pr-lg-4 text-white primary-bg-color">{data.due_date_h}</td>
                                    </tr>
                                )
                                :
                                <tr className='f-18 text-center'>
                                    <td colSpan={3}>
                                        There are no goal in here. Create one by clicking the "Create new" button in the top right corner.
                                    </td>
                                </tr>
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

Table.propTypes = {
    setDeleteOpen: PropTypes.func,
    setCreateOrEditGoalOpen: PropTypes.func,
    tableData: PropTypes.array,
    setGoalId: PropTypes.func,
    handleCheckEvent: PropTypes.func,
    setEditGoalId: PropTypes.func
};

export default Table;