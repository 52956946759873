import React from 'react';

const Loader = () => {
    return (
        <div className='loader'>
            <div className="loading">
                <div>
                    <div className="c1"/>
                    <div className="c2"/>
                    <div className="c3"/>
                    <div className="c4"/>
                </div>
            </div>
        </div>
    );
};

export default Loader;