import React from "react";
import { all, takeEvery, put, call } from 'redux-saga/effects';
import {toast} from 'react-toastify';

import { GET_MARKETING_LIST, CREATE_OR_EDIT_MARKETING, DELETE_MARKETING, GET_MARKETING_DETAIL } from '../action/types';
import API from '../../utils/api';
import {
    getMarketingListSuccess,
    getMarketingListFailure,
    deleteMarketingSuccess,
    deleteMarketingFailure,
    getMarketingList,
    createOrEditMarketingSuccess,
    createOrEditMarketingFailure,
    getMarketingDetailSuccess,
    getMarketingDetailFailure,
    resetMarketingDetail
} from '../action';
import {ErrorToast, SuccessToast} from "../../utils/helper";

function* getMarketingListRequest(action) {
    try{
        const desc = action.payload.show_docs_for ? 'show_docs_for=' + action.payload.show_docs_for : '';
        const ord = action.payload.order_by ? '&order_by=' + action.payload.order_by : '';
        const res = yield API.get('marketing?' + desc + ord);
        yield put(getMarketingListSuccess(res.data.data));
    }catch (e) {
        yield put(getMarketingListFailure(e));
    }
}

function* createOrEditMarketingRequest(action) {
    const { title, contents, status, created_for, externals }=action.payload.form;
    const url = action.payload.id ? 'marketing/edit' : 'marketing/add';
    const bodyFormData = new FormData();
    action.payload.id && bodyFormData.append('id', action.payload.id);
    bodyFormData.append('title', title);
    bodyFormData.append('contents', contents);
    bodyFormData.append('raw_contents', contents);
    bodyFormData.append('type', 'marketing');
    bodyFormData.append('status', status);
    bodyFormData.append('created_for', created_for);
    externals && externals.map(itm =>
        bodyFormData.append('externals[]', itm)
    )
    try {
        const res = yield API.post(url, bodyFormData);
        if(res.data.meta.code === 1) {
            yield put(createOrEditMarketingSuccess());
            yield put(getMarketingList());
            yield call(action.payload.callback);
            yield toast.success(<SuccessToast msg={res.data.meta.message}/>);
            yield put(resetMarketingDetail());
        }else if(res.data.meta.code === 0){
            yield put(createOrEditMarketingFailure());
            yield toast.error(<ErrorToast msg={res.data.meta.message}/>);
        }
    }catch (e) {
        yield put(createOrEditMarketingFailure());
        yield toast.error(<ErrorToast msg='Something went wrong.'/>);
    }
}

function* deleteMarketingRequest(action) {
    try {
        const res = yield API.delete(`marketing/${action.payload.id}`);
        if(res.data.meta.code === 1) {
            yield put(deleteMarketingSuccess());
            yield call(action.payload.callback);
            yield toast.success(<SuccessToast msg={res.data.meta.message}/>);
            yield put(getMarketingList());
        }else if(res.data.meta.code === 0) {
            yield put(deleteMarketingFailure());
            yield toast.error(<ErrorToast msg={res.data.meta.message}/>);
        }
    } catch (e) {
        yield put(deleteMarketingFailure());
        yield toast.error(<ErrorToast msg='Something went wrong.'/>);
    }
}

function* getMarketingDetailRequest(action) {
    try{
        const res = yield API.get(`marketing/${action.payload}`);
        yield put(getMarketingDetailSuccess(res.data.data));
    }catch (e) {
        yield put(getMarketingDetailFailure(e));
    }
}

export function* watchCreateOrEditMarketingAPI() {
    yield takeEvery(CREATE_OR_EDIT_MARKETING, createOrEditMarketingRequest);
}

export function* watchMarketingListAPI() {
    yield takeEvery(GET_MARKETING_LIST, getMarketingListRequest);
}

export function* watchDeleteMarketingAPI() {
    yield takeEvery(DELETE_MARKETING, deleteMarketingRequest)
}

export function* watchMarketingDetailAPI() {
    yield takeEvery(GET_MARKETING_DETAIL, getMarketingDetailRequest);
}

export default function* rootSaga() {
    yield all([
        watchMarketingListAPI(),
        watchCreateOrEditMarketingAPI(),
        watchDeleteMarketingAPI(),
        watchMarketingDetailAPI(),
    ]);
}