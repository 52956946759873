import {all, call, put, takeEvery} from 'redux-saga/effects';
import React from "react";
import {toast} from "react-toastify";

import {
    ADD_EDIT_CALENDAR_EVENT,
    DELETE_CALENDAR_EVENT,
    GET_CALENDAR_EVENT,
    GET_EVENT_DETAILS_BY_ID,
    RESPOND__EVENT
} from '../action/types';
import {
    addOrEditCalendarEventFailure,
    addOrEditCalendarEventSuccess,
    deleteCalendarEventFailure,
    deleteCalendarEventSuccess,
    getCalendarEventFailure,
    getCalendarEventSuccess,
    getEventDetailsByIdFailure,
    getEventDetailsByIdSuccess,
    respondEventEventFailure,
    respondEventEventSuccess,
    getCalendarEvent
} from '../action';
import API from '../../utils/api';
import {ErrorToast, getUser, SuccessToast} from "../../utils/helper";
import moment from "moment";

function* getCalendarEventRequest() {
    try {
        const res = yield API.get('calender');
        if (res.data.meta.code === 1) {
            yield put(getCalendarEventSuccess(res.data.data))
        } else {
            yield put(getCalendarEventFailure(res.data))
        }
    } catch (e) {
        yield put(getCalendarEventFailure(e))
    }
}

function* AddEditEvent(action) {
    const {event,location,attendees,duration,time_opt1,time_opt2,time_opt3,time_opt4,date_opt1,date_opt2,date_opt3,date_opt4,emails,note} = action.payload.form
    const url = action.payload.editId ? 'calender/edit' : 'calender/add';
    const bodyFormData = new FormData();
    action.payload.editId && bodyFormData.append('event_id', action.payload.editId);
    bodyFormData.append('summary', event);
    bodyFormData.append('location', location);
    bodyFormData.append('duration', duration);
    !action.payload.editId && emails && bodyFormData.append('emails', emails);
    bodyFormData.append('time_opt1', time_opt1);
    time_opt2 && bodyFormData.append('time_opt2', time_opt2);
    time_opt3 && bodyFormData.append('time_opt3', time_opt3);
    time_opt4 && bodyFormData.append('time_opt4', time_opt4);
    bodyFormData.append('date_opt1',  moment(date_opt1).format('DD/MM/YYYY'));
    date_opt2 && bodyFormData.append('date_opt2',  moment(date_opt2).format('DD/MM/YYYY'));
    date_opt3 && bodyFormData.append('date_opt3',  moment(date_opt3).format('DD/MM/YYYY'));
    date_opt4 && bodyFormData.append('date_opt4',  moment(date_opt4).format('DD/MM/YYYY'));
    !action.payload.editId && note && bodyFormData.append('note', note);
    !action.payload.editId && attendees && attendees.map(itm =>
        bodyFormData.append('attendees[]', itm)
    )
    try {
        const res = yield API.post(url, bodyFormData);
        if (res.data.meta.code === 1) {
            yield put(addOrEditCalendarEventSuccess());
            yield call(action.payload.callback)
            yield put(getCalendarEvent())
            yield toast.success(<SuccessToast msg={res.data.meta.message}/>);
        } else {
            yield put(addOrEditCalendarEventFailure());
            yield call(action.payload.callback)
            yield toast.error(<ErrorToast msg={res.data.meta.message}/>);
        }
    } catch (e) {
        yield put(addOrEditCalendarEventFailure());
        yield toast.error(<ErrorToast msg='Something went wrong.'/>);
    }
}

function* getEventDetailsRequest(action) {
    try {
        const res = yield API.get(`calender/${action.payload}`);

        if (res.data.meta.code === 1) {
            yield put(getEventDetailsByIdSuccess(res.data.data))
        } else {
            yield put(getEventDetailsByIdFailure(res))
        }
    } catch (e) {
        yield put(getEventDetailsByIdFailure(e))
    }
}

function* DeleteEventRequest(action) {
    try {
        const res = yield API.delete('calender/' + JSON.parse(getUser()).id + '?event_id=' + action.payload.id);
        if (res.data.meta.code === 1) {
            yield put(deleteCalendarEventSuccess(res.data));
            yield call(action.payload.callback)
            yield put(getCalendarEvent());
            yield toast.success(<SuccessToast msg={res.data.meta.message}/>);
        } else if (res.data.meta.code === 0) {
            yield put(deleteCalendarEventFailure());
            yield toast.error(<ErrorToast msg={res.data.meta.message}/>);
        }
    } catch (e) {
        yield put(deleteCalendarEventFailure());
        yield toast.error(<ErrorToast msg='Something went wrong.'/>);
    }
}

function* respondEvent(action) {
    let data = new FormData();
    data.append('event_id', action.payload.event_id)
    data.append('selected_time_slot', action.payload.selected_time_slot)
    data.append('response', action.payload.response)
    try {
        const res = yield API.post(`calender/${action.payload.event_id}/respond`, data);
        if (res.data.meta.code === 1) {
            yield put(respondEventEventSuccess(res.data.data))
            yield put(getCalendarEvent())
            yield toast.success(<SuccessToast msg={res.data.meta.message}/>);
        } else {
            yield put(respondEventEventFailure(res))
            yield toast.error(<ErrorToast msg={res.data.meta.message}/>);
        }
    } catch (e) {
        yield put(respondEventEventFailure(e))
        yield toast.error(<ErrorToast msg='Something went wrong.'/>);
    }
}

export function* watchGetCalendarEventAPI() {
    yield takeEvery(GET_CALENDAR_EVENT, getCalendarEventRequest)
}

export function* watchADDEditEventAPI() {
    yield takeEvery(ADD_EDIT_CALENDAR_EVENT, AddEditEvent)
}

export function* watchGetEventDetailsAPI() {
    yield takeEvery(GET_EVENT_DETAILS_BY_ID, getEventDetailsRequest)
}

export function* watchRespondEventAPI() {
    yield takeEvery(RESPOND__EVENT, respondEvent)
}

export function* watchDeleteEventAPI() {
    yield takeEvery(DELETE_CALENDAR_EVENT, DeleteEventRequest)
}

export default function* rootSaga() {
    yield all([
        watchGetCalendarEventAPI(),
        watchADDEditEventAPI(),
        watchGetEventDetailsAPI(),
        watchRespondEventAPI(),
        watchDeleteEventAPI(),
    ]);
}
