import React from "react";
import {all, put, takeEvery,call} from 'redux-saga/effects';
import {toast} from "react-toastify";

import {GET_VISIONBOARD_LIST, UPLOAD_VISIONBOARD} from '../action/types';
import API from '../../utils/api';
import {
    getVisionboardListFailure,
    getVisionboardListSuccess,
    uploadVISIONBOARDFailure,
    uploadVISIONBOARDSuccess
} from '../action';
import {ErrorToast, SuccessToast} from "../../utils/helper";

function* getVisionboardListRequest(action) {
    try {
        const desc = action.payload.athlete_id ? '?athlete=' + action.payload.athlete_id : '';
        const res = yield API.get('visionboard' + desc);
        if (res.data.meta.code === 1) {
            yield put(getVisionboardListSuccess(res.data.data));
        } else {
            yield put(getVisionboardListFailure(res));
        }
    } catch (e) {
        yield put(getVisionboardListFailure(e));
    }
}

function* uploadVisionBoard(action) {
    const bodyFormData = new FormData();
    let str = 'Photo';
    str =  str+action.payload.req.id;
    bodyFormData.append('name', str);
    bodyFormData.append('file', action.payload.req.files[0]);

    try {
        const res = yield API.post('visionboard/addFile', bodyFormData);
        if (res.data.meta.code === 1) {
            yield put(uploadVISIONBOARDSuccess(res.data));
            yield toast.success(<SuccessToast msg={res.data.meta.message}/>);
            yield call(action.payload.callback);
        } else {
            yield put(uploadVISIONBOARDFailure());
            yield toast.error(<ErrorToast msg={res.data.meta.message}/>);
        }
    } catch (e) {
        yield put(uploadVISIONBOARDFailure(e));
        yield toast.error(<ErrorToast msg='Something went wrong.'/>);
    }
}

export function* watchVisionboardListAPI() {
    yield takeEvery(GET_VISIONBOARD_LIST, getVisionboardListRequest);
}

export function* watchUploadVisionboard() {
    yield takeEvery(UPLOAD_VISIONBOARD, uploadVisionBoard);
}

export default function* rootSaga() {
    yield all([
        watchUploadVisionboard(),
        watchVisionboardListAPI(),
    ]);
}
