import React from "react";
import { all, takeEvery, put, call } from 'redux-saga/effects';
import {toast} from 'react-toastify';
import moment from "moment";

import {
    CREATE_OR_EDIT_GOALS,
    VIEW_GOALS_LIST,
    COMPLETED_INCOMPLETED_GOAL,
    DELETE_GOAL,
    GET_GOAL_DETAIL
} from '../action/types';
import {

    createOrEditGoalsSuccess,
    createOrEditGoalsFailure,

    viewGoalsList,
    viewGoalsListSuccess,
    viewGoalsListFailure,

    completedIncompletedGoalSuccess,
    completedIncompletedGoalFailure,

    deleteGoalSuccess,
    deleteGoalFailure,

    getGoalDetailSuccess,
    getGoalDetailFailure,
    resetGoalDetail,
} from '../action';
import API from '../../utils/api';
import {ErrorToast, SuccessToast} from "../../utils/helper";
import {getUser} from "../../utils/helper";


//Create or Edit Goals API Request
function* createOrEditGoalsRequest(action) {
    const {title, start_date, due_date, benchmark, situational_analysis, golden_circle} = action.payload.form;
    const url = action.payload.id ? `athletes/${action.payload.athleteId}/goals/edit`:`athletes/${action.payload.athleteId}/goals/add`;
    const successText = action.payload.id ? 'Goal Updated' : "Goal Created";
    const bodyFormData = new FormData();
    action.payload.id && bodyFormData.append('goal_id', action.payload.id);
    bodyFormData.append('title', title);
    bodyFormData.append('start_date', moment(start_date).format('DD/MM/YYYY'));
    bodyFormData.append('due_date', moment(due_date).format('DD/MM/YYYY'));
    bodyFormData.append('benchmark', benchmark);
    bodyFormData.append('situational_analysis', situational_analysis);
    bodyFormData.append('golden_circle', golden_circle);
    try {
        yield API.post(url, bodyFormData);
        yield put(createOrEditGoalsSuccess());
        yield put(viewGoalsList());
        yield toast.success(<SuccessToast msg={successText}/>);
        yield put(resetGoalDetail());
        yield call(action.payload.callBack);
    }catch (e) {
        yield put(createOrEditGoalsFailure());
    }
}

//View Goals List API Request
function* viewGoalsRequest(action) {
    let url;

    const id = JSON.parse(getUser()).id;

    if(action.payload) {
        url = JSON.parse(getUser()).is_admin ? `goals?start=${action.payload.start}&end=${action.payload.end}` : `athletes/${id}/goals?start=${action.payload.start}&end=${action.payload.end}`;
    } else {
        url = JSON.parse(getUser()).is_admin ? `goals` : `athletes/${id}/goals` ;
    }
    try {
        const res = yield API.get(url);
        yield put(viewGoalsListSuccess(res.data));
    }catch (e) {
        yield put(viewGoalsListFailure(e));
    }
}

// Completed Incompleted Goal API Request
function* completedIncompletedGoalRequest(action) {
    const {goal_id, completed} = action.payload;
    const bodyFormData = new FormData();
    const message = completed === 0 ? "Goal set to incomplete" : "Goal Completed" ;
    bodyFormData.append('goal_id', goal_id);
    bodyFormData.append('completed', completed);
    try {
        const res = yield API.post(`goals-completed`, bodyFormData);
        if(res.data.meta.code === 1) {
            yield put(completedIncompletedGoalSuccess());
            yield put(viewGoalsList());
            yield toast.success(<SuccessToast msg={message}/>);
        }else if(res.data.meta.code === 0) {
            yield put(completedIncompletedGoalFailure());
            yield toast.error(<ErrorToast msg={res.data.meta.message}/>);
        }
    }catch (e) {
        yield put(completedIncompletedGoalFailure());
        yield toast.error(<ErrorToast msg='Something went wrong.'/>);
    }
}

//Delete Goal API Request
function* deleteGoalRequest(action) {
    const id = JSON.parse(getUser()).is_admin ? 1 : JSON.parse(getUser()).id;
    try {
        const res = yield API.delete(`athletes/${id}/goals/${action.payload.id}`);
        if(res.data.meta.code === 1) {
            yield put(deleteGoalSuccess());
            yield call(action.payload.callBack);
            yield toast.success(<SuccessToast msg={res.data.meta.message}/>);
            yield put(viewGoalsList());
        }else if(res.data.meta.code === 0) {
            yield put(deleteGoalFailure());
            yield toast.error(<ErrorToast msg={res.data.meta.message}/>);
        }
    }catch (e) {
        yield put(deleteGoalFailure());
        yield toast.error(<ErrorToast msg='Something went wrong.'/>);
    }
}

// Goal detail goal api request
function* getGoalDetailRequest(action) {
    try {
        const res = yield API.get(`goals/${action.payload}`);
        yield put(getGoalDetailSuccess(res.data));
    } catch (e) {
        yield put(getGoalDetailFailure(e));
    }
}

//Create or Edit Goals Watcher
export function* watchCreateOrEditGoalsAPI() {
    yield takeEvery(CREATE_OR_EDIT_GOALS, createOrEditGoalsRequest);
}

//View Goals List Watcher
export function* watchGoalsListAPI() {
    yield takeEvery(VIEW_GOALS_LIST, viewGoalsRequest);
}

//Completed and Incompleted Goals Watcher
export function* watchCompletedIncompletedGoalsAPI() {
    yield takeEvery(COMPLETED_INCOMPLETED_GOAL, completedIncompletedGoalRequest);
}

//Delete Goal Watcher
export function* watchDeleteGoalAPI() {
    yield takeEvery(DELETE_GOAL, deleteGoalRequest);
}

//Goal Detail Watcher
export function* watchGoalDetailAPI() {
    yield takeEvery(GET_GOAL_DETAIL, getGoalDetailRequest);
}


export default function* rootSaga() {
    yield all([
        watchCreateOrEditGoalsAPI(),
        watchGoalsListAPI(),
        watchCompletedIncompletedGoalsAPI(),
        watchDeleteGoalAPI(),
        watchGoalDetailAPI(),
    ]);
}
