import React from 'react';
import {Editor} from '@tinymce/tinymce-react';
import PropTypes from 'prop-types';

const RichTextEditor = ({handleChange, value, disabled}) => {
    return (
        <>
            <Editor
                init={{
                    height: 500,
                    menubar: 'file edit view insert format tools table tc help',
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount',
                        'image imagetools',
                        'media mediaembed'
                    ],
                    toolbar:
                    // eslint-disable-next-line no-multi-str
                        'undo redo | formatselect | bold italic backcolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | removeformat | help | image | media mediaembed'
                }}
                value={value}
                onEditorChange={handleChange}
                disabled={disabled}
            />
        </>
    )
};

RichTextEditor.propTypes = {
    handleChange: PropTypes.func,
    value: PropTypes.string,
    disabled:PropTypes.bool,
};

export default RichTextEditor;