import React from 'react';
import PropTypes from 'prop-types';
import {Link, useHistory, withRouter} from "react-router-dom";

const ProfileCard = ({
                         userImage,
                         userFirstName,
                         userLastName,
                         clubName,
                         isEdit,
                         isDelete,
                         isView,
                         isAthlete,
                         setDeleteOpen,
                         data,
                         setDeleteId,
                         match
                     }) => {
    const history = useHistory();

    const handleClick = () => {
        if(isView) {
            history.push(`${match.url}/dashboard/${data.id}`);
        }
    }
    return (
        <div className="customCard">
            <div>
                <div className={isView ? 'cursor-pointer profileCardImg' : 'profileCardImg'} onClick={handleClick}>
                    <img src={userImage} className='img-fluid' alt='avatar'/>
                </div>
                <div className="profileCardContent">
                    <h5 className="profileCardName">{userFirstName}<br/>{userLastName}</h5>
                    {
                        isAthlete && <p className="profileCardDescription f-16">
                            <span className="f-14 montserrat-bold">CLUB</span>
                            <br/>
                            {clubName}
                        </p>
                    }
                    <div className="profileCardButton font-weight-bold">
                        {
                            isEdit &&
                            <Link
                                className="customBtn montserrat-bold  f-16 ml-2 cursor-pointer"
                                to={`${match.url}/${data.id}/edit`}
                            >
                                Edit
                            </Link>
                        }
                        {
                            isDelete &&
                            <span
                                className="customBtn montserrat-bold f-16 ml-2 cursor-pointer"
                                onClick={() => {
                                    setDeleteOpen(true);
                                    setDeleteId(data.id);
                                }}
                            >
                                    Delete
                                </span>
                        }
                        {
                            isView &&
                            <Link
                                to={`${match.url}/dashboard/${data.id}`}
                                className="customBtn f-16 ml-2 dashboard montserrat-bold"
                            >
                                Dashboard
                            </Link>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

ProfileCard.propTypes = {
    userImage: PropTypes.string,
    userFirstName: PropTypes.string,
    userLastName: PropTypes.string,
    clubName: PropTypes.string,
    isEdit: PropTypes.bool,
    isDelete: PropTypes.bool,
    isView: PropTypes.bool,
    isAthlete: PropTypes.bool,
    setDeleteOpen: PropTypes.func,
    data: PropTypes.object,
    setDeleteId: PropTypes.func,
    match: PropTypes.shape({
        url: PropTypes.string,
    })
}

export default withRouter(ProfileCard);
