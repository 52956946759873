import {
    GET_CALENDAR_EVENT,
    GET_CALENDAR_EVENT_SUCCESS,
    GET_CALENDAR_EVENT_FAILURE,

    ADD_EDIT_CALENDAR_EVENT,
    ADD_EDIT_CALENDAR_EVENT_SUCCESS,
    ADD_EDIT_CALENDAR_EVENT_FAILURE,

    DELETE_CALENDAR_EVENT,
    DELETE_CALENDAR_EVENT_SUCCESS,
    DELETE_CALENDAR_EVENT_FAILURE,

    GET_EVENT_DETAILS_BY_ID,
    GET_EVENT_DETAILS_BY_ID_SUCCESS,
    GET_EVENT_DETAILS_BY_ID_FAILURE, RESPOND__EVENT_SUCCESS, RESPOND_EVENT_FAILURE, RESPOND__EVENT, RESET_EVENT_DETAIL,
} from './types'

export const getCalendarEvent = () => ({
    type: GET_CALENDAR_EVENT
});

export const getCalendarEventSuccess = (payload) => ({
    type: GET_CALENDAR_EVENT_SUCCESS,
    payload
});

export const getCalendarEventFailure = payload => ({
   type: GET_CALENDAR_EVENT_FAILURE,
   payload
});

export const addOrEditCalendarEvent = payload => ({
    type: ADD_EDIT_CALENDAR_EVENT,
    payload
});

export const addOrEditCalendarEventSuccess = () => ({
    type: ADD_EDIT_CALENDAR_EVENT_SUCCESS
});

export const addOrEditCalendarEventFailure = () => ({
    type: ADD_EDIT_CALENDAR_EVENT_FAILURE
});

export const deleteCalendarEvent = payload => ({
    type: DELETE_CALENDAR_EVENT,
    payload
});

export const ResetEventDetail = () => ({
    type: RESET_EVENT_DETAIL
});

export const deleteCalendarEventSuccess = () => ({
   type: DELETE_CALENDAR_EVENT_SUCCESS
});

export const deleteCalendarEventFailure = () => ({
    type: DELETE_CALENDAR_EVENT_FAILURE
});

export const getEventDetailsById = (payload) => ({
    type: GET_EVENT_DETAILS_BY_ID,
    payload
});

export const getEventDetailsByIdSuccess = payload => ({
    type: GET_EVENT_DETAILS_BY_ID_SUCCESS,
    payload
});

export const getEventDetailsByIdFailure = payload => ({
    type: GET_EVENT_DETAILS_BY_ID_FAILURE,
    payload
});


export const respondEvent = payload => ({
    type: RESPOND__EVENT,
    payload
});

export const respondEventEventSuccess = () => ({
    type: RESPOND__EVENT_SUCCESS
});

export const respondEventEventFailure = () => ({
    type: RESPOND_EVENT_FAILURE
});
