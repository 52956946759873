import React from "react";
import {all, call, put, takeEvery} from 'redux-saga/effects';
import {toast} from "react-toastify";

import {
    CREATE_OR_EDIT_HIGHLIGHTS,
    DELETE_HIGHLIGHTS,
    GET_HIGHLIGHTS_DETAIL,
    GET_HIGHLIGHTS_LIST
} from "../action/types";
import API from '../../utils/api';
import {
    createOrEditHighlightsFailure,
    createOrEditHighlightsSuccess,
    deleteHIGHLIGHTSFailure,
    deleteHIGHLIGHTSSuccess,
    getHIGHLIGHTSDetailFailure,
    getHIGHLIGHTSDetailSuccess,
    getHighlightsListFailure,
    getHighlightsListSuccess,
    resetHIGHLIGHTSDetail
} from "../action";
import {ErrorToast, SuccessToast} from "../../utils/helper";

function* createOrEditHighlightsRequest(action) {
    const {title, contents, status, created_for, externals} = action.payload.form;
    const url = action.payload.id ? 'highlights/edit' : 'highlights/add';
    const bodyFormData = new FormData();
    action.payload.id && bodyFormData.append('id', action.payload.id);
    bodyFormData.append('title', title);
    bodyFormData.append('contents', contents);
    bodyFormData.append('raw_contents', contents);
    bodyFormData.append('pic', '');
    bodyFormData.append('type', 'highlights');
    bodyFormData.append('status', status);
    bodyFormData.append('created_for', created_for);
    externals && externals.map(itm =>
        bodyFormData.append('externals[]', itm)
    )
    try {
        const res = yield API.post(url, bodyFormData);
        if (res.data.meta.code === 1) {
            yield put(createOrEditHighlightsSuccess());
            yield call(action.payload.callback);
            yield toast.success(<SuccessToast msg={res.data.meta.message}/>);
            yield put(resetHIGHLIGHTSDetail());
        } else if (res.data.meta.code === 0) {
            yield put(createOrEditHighlightsFailure());
            yield toast.error(<ErrorToast msg={res.data.meta.message}/>);
        }
    } catch (e) {
        yield put(createOrEditHighlightsFailure());
        yield toast.error(<ErrorToast msg='Something went wrong.'/>);
    }
}

function* getHighlightListRequest(action) {
    try {
        const desc = action.payload.show_docs_for ? 'show_docs_for=' + action.payload.show_docs_for : '';
        const ord = action.payload.order_by ? '&order_by=' + action.payload.order_by : '';
        const res = yield API.get('highlights?' + desc + ord);
        yield put(getHighlightsListSuccess(res.data.data))
    } catch (e) {
        yield put(getHighlightsListFailure(e));
    }
}

function* getHIGHLIGHTSDetail(action) {
    try {
        const res = yield API.get(`highlights/${action.payload}`);
        yield put(getHIGHLIGHTSDetailSuccess(res.data));
    } catch (e) {
        yield put(getHIGHLIGHTSDetailFailure(e));
    }
}

function* deleteHighlightsRequest(action) {
    try {
        const res = yield API.delete(`highlights/${action.payload.id}`);
        if (res.data.meta.code === 1) {
            yield put(deleteHIGHLIGHTSSuccess());
            yield call(action.payload.calBack);
            yield toast.success(<SuccessToast msg={res.data.meta.message}/>);
        } else if (res.data.mete.code === 0) {
            yield put(deleteHIGHLIGHTSFailure());
            yield toast.error(<ErrorToast msg={res.data.meta.message}/>);
        }
    } catch (e) {
        yield put(deleteHIGHLIGHTSFailure());
        yield toast.error(<ErrorToast msg='Something went wrong.'/>);
    }
}

export function* watchHighlightsListAPI() {
    yield takeEvery(GET_HIGHLIGHTS_LIST, getHighlightListRequest);
}

export function* watchHighlightsDetailAPI() {
    yield takeEvery(GET_HIGHLIGHTS_DETAIL, getHIGHLIGHTSDetail);
}

export function* watchDeleteHIGHLIGHTSAPI() {
    yield takeEvery(DELETE_HIGHLIGHTS, deleteHighlightsRequest)
}

export function* createOrEditHighlights() {
    yield takeEvery(CREATE_OR_EDIT_HIGHLIGHTS, createOrEditHighlightsRequest);
}

export default function* rootSaga() {
    yield all([
        watchHighlightsListAPI(),
        watchHighlightsDetailAPI(),
        watchDeleteHIGHLIGHTSAPI(),
        createOrEditHighlights()
    ]);
}
