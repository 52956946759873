import {

    GET_MARKETING_LIST,
    GET_MARKETING_LIST_SUCCESS,
    GET_MARKETING_LIST_FAILURE,

    CREATE_OR_EDIT_MARKETING,
    CREATE_OR_EDIT_MARKETING_SUCCESS,
    CREATE_OR_EDIT_MARKETING_FAILURE,

    DELETE_MARKETING,
    DELETE_MARKETING_SUCCESS,
    DELETE_MARKETING_FAILURE,

    GET_MARKETING_DETAIL,
    GET_MARKETING_DETAIL_SUCCESS,
    GET_MARKETING_DETAIL_FAILURE,
    RESET_MARKETING_DETAIL,

} from '../action/types';

const INIT_STATE = {
    loading: false,
    marketingList: {},
    marketingDetail: {}
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_MARKETING_LIST:
            return { ...state, loading: true };
        case GET_MARKETING_LIST_SUCCESS:
            return { ...state, loading: false, marketingList: action.payload };
        case GET_MARKETING_LIST_FAILURE:
            return { ...state, loading: false, marketingList: action.payload };

        case CREATE_OR_EDIT_MARKETING:
            return { ...state, loading: true };
        case CREATE_OR_EDIT_MARKETING_SUCCESS:
            return { ...state, loading: false };
        case CREATE_OR_EDIT_MARKETING_FAILURE:
            return { ...state, loading: false };
        case DELETE_MARKETING:
            return { ...state, loading: true };
        case DELETE_MARKETING_SUCCESS:
            return { ...state, loading: false };
        case DELETE_MARKETING_FAILURE:
            return { ...state, loading: false };
        case GET_MARKETING_DETAIL:
            return { ...state, loading: true };
        case GET_MARKETING_DETAIL_SUCCESS:
            return { ...state, loading: false, marketingDetail: action.payload };
        case GET_MARKETING_DETAIL_FAILURE:
            return { ...state, loading: false, marketingDetail: action.payload };
        case RESET_MARKETING_DETAIL:
            return { ...state, marketingDetail: {} };
        default:
            return state;
    }
};