import React from 'react';
import PropTypes from 'prop-types';
import {SortUp, PersonFill} from 'react-bootstrap-icons';

const DropDown = ({
	dropDownHeading,
	dropDownSelected,
	dropDownOption,
	onselect,
	name,
	value,
	variant,
}) => {
	const Icon = {sort: SortUp, person: PersonFill}[dropDownHeading];
	return (
		<>
			<label className='f-14 mr-2 mr-1 mb-2 mt-lg-4 mt-2 align-self-center'>
				{variant === 'icon' ? (
					<Icon className='mb-2' size={24} />
				) : (
					dropDownHeading
				)}
			</label>
			<span className='mb-2 mt-lg-4 mt-2'>
				<select
					name={name}
					onChange={onselect}
					value={value}
					className='bg-transparent border-0 f-18 cursor-pointer'>
					<option value='' selected>
						{dropDownSelected}
					</option>
					{dropDownOption.map((option, index) => (
						<option key={index} value={option.value}>
							{option.name}
						</option>
					))}
				</select>
			</span>
		</>
	);
};

DropDown.propTypes = {
	dropDownHeading: PropTypes.string,
	dropDownSelected: PropTypes.string,
	dropDownOption: PropTypes.array,
	name: PropTypes.string,
	value: PropTypes.string,
	onselect: PropTypes.func,
};

export default DropDown;
