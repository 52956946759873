import {
    CREATE_OR_EDIT_HIGHLIGHTS,
    CREATE_OR_EDIT_HIGHLIGHTS_FAILURE,
    CREATE_OR_EDIT_HIGHLIGHTS_SUCCESS,
    GET_HIGHLIGHTS_DETAIL,
    GET_HIGHLIGHTS_DETAIL_FAILURE,
    GET_HIGHLIGHTS_DETAIL_SUCCESS,
    GET_HIGHLIGHTS_LIST, GET_HIGHLIGHTS_LIST_FAILURE,
    GET_HIGHLIGHTS_LIST_SUCCESS,
    RESET_HIGHLIGHTS_DETAIL
} from "../action/types";

const INIT_STATE = {
    loading: false,
    highlightList: {},
    highlightsDetail: {},
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CREATE_OR_EDIT_HIGHLIGHTS:
            return {...state, loading: true};
        case CREATE_OR_EDIT_HIGHLIGHTS_SUCCESS:
            return {...state, loading: false};
        case CREATE_OR_EDIT_HIGHLIGHTS_FAILURE:
            return {...state, loading: false};
        case GET_HIGHLIGHTS_LIST:
            return {...state, loading: true};
        case GET_HIGHLIGHTS_LIST_SUCCESS:
            return {...state, loading: false, highlightList: action.payload};
        case GET_HIGHLIGHTS_LIST_FAILURE:
            return {...state, loading: false, highlightList: action.payload};
        case GET_HIGHLIGHTS_DETAIL:
            return {...state, loading: true};
        case GET_HIGHLIGHTS_DETAIL_SUCCESS:
            return {...state, loading: false, highlightsDetail: action.payload};
        case GET_HIGHLIGHTS_DETAIL_FAILURE:
            return {...state, loading: false, highlightsDetail: action.payload};
        case RESET_HIGHLIGHTS_DETAIL:
            return {...state, highlightsDetail: {}};
        default:
            return state;

    }
}
