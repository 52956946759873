import {
    CREATE_OR_EDIT_EXTERNAL_USER,
    CREATE_OR_EDIT_EXTERNAL_USER_FAILURE,
    CREATE_OR_EDIT_EXTERNAL_USER_SUCCESS,

    DELETE_EXTERNAL_USER,
    DELETE_EXTERNAL_USER_FAILURE,
    DELETE_EXTERNAL_USER_SUCCESS,

    GET_EXTERNAL_USER_LIST,
    GET_EXTERNAL_USER_LIST_FAILURE,
    GET_EXTERNAL_USER_LIST_SUCCESS,

    GET_EXTERNAL_USER_DETAIL,
    GET_EXTERNAL_USER_DETAIL_FAILURE,
    GET_EXTERNAL_USER_DETAIL_SUCCESS,
    RESET_EXTERNAL_USER_DETAIL,
} from '../action/types';

const INIT_STATE = {
    loading: false,
    usersList: null,
    externalUserDetail: {}
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case CREATE_OR_EDIT_EXTERNAL_USER:
            return {...state, loading: true};
        case CREATE_OR_EDIT_EXTERNAL_USER_FAILURE:
            return {...state, loading: false};
        case CREATE_OR_EDIT_EXTERNAL_USER_SUCCESS:
            return {...state, loading: false};

        case GET_EXTERNAL_USER_LIST:
            return {...state, loading: true};
        case GET_EXTERNAL_USER_LIST_FAILURE:
            return {...state, loading: false, usersList: action.payload};
        case GET_EXTERNAL_USER_LIST_SUCCESS:
            return {...state, loading: false, usersList: action.payload};

        case DELETE_EXTERNAL_USER:
            return {...state, loading: true};
        case DELETE_EXTERNAL_USER_SUCCESS:
            return {...state, loading: false};
        case DELETE_EXTERNAL_USER_FAILURE:
            return {...state, loading: false};

        case GET_EXTERNAL_USER_DETAIL:
            return { ...state, loading: true };
        case GET_EXTERNAL_USER_DETAIL_FAILURE:
            return { ...state, loading: false, externalUserDetail: action.payload };
        case GET_EXTERNAL_USER_DETAIL_SUCCESS:
            return { ...state, loading: false, externalUserDetail: action.payload };
        case RESET_EXTERNAL_USER_DETAIL:
            return { ...state, externalUserDetail: {} };

        default:
            return state;
    }
};
