import {
    CREATE_OR_EDIT_GOALS,
    CREATE_OR_EDIT_GOALS_SUCCESS,
    CREATE_OR_EDIT_GOALS_FAILURE,

    VIEW_GOALS_LIST,
    VIEW_GOALS_LIST_SUCCESS,
    VIEW_GOALS_LIST_FAILURE,

    COMPLETED_INCOMPLETED_GOAL,
    COMPLETED_INCOMPLETED_GOAL_SUCCESS,
    COMPLETED_INCOMPLETED_GOAL_FAILURE,

    DELETE_GOAL,
    DELETE_GOAL_SUCCESS,
    DELETE_GOAL_FAILURE,

    GET_GOAL_DETAIL,
    GET_GOAL_DETAIL_SUCCESS,
    GET_GOAL_DETAIL_FAILURE,
    RESET_GOAL_DETAIL
} from './types';

// Create Or Edit Goals
export const createOrEditGoals = payload => ({
    type: CREATE_OR_EDIT_GOALS,
    payload
});

export const createOrEditGoalsSuccess = () => ({
    type: CREATE_OR_EDIT_GOALS_SUCCESS
});

export const createOrEditGoalsFailure= () => ({
    type: CREATE_OR_EDIT_GOALS_FAILURE
});

// View Goals List
export const viewGoalsList = (payload) => ({
    type: VIEW_GOALS_LIST,
    payload
});

export const viewGoalsListSuccess = (payload) => ({
    type: VIEW_GOALS_LIST_SUCCESS,
    payload
});

export const viewGoalsListFailure= (payload) => ({
    type: VIEW_GOALS_LIST_FAILURE,
    payload
});

//Completed and Incompleted Goals
export const completedIncompletedGoal = payload => ({
    type: COMPLETED_INCOMPLETED_GOAL,
    payload
});

export const completedIncompletedGoalSuccess = (payload) => ({
    type: COMPLETED_INCOMPLETED_GOAL_SUCCESS,
    payload
});

export const completedIncompletedGoalFailure= (payload) => ({
    type: COMPLETED_INCOMPLETED_GOAL_FAILURE,
    payload
});

// Delete Completed Goal
export const deleteGoal = payload => ({
    type: DELETE_GOAL,
    payload
});

export const deleteGoalSuccess = () => ({
    type: DELETE_GOAL_SUCCESS
});

export const deleteGoalFailure= () => ({
    type: DELETE_GOAL_FAILURE
});

// Get Goal detail
export const getGoalDetail = (payload) => ({
    type: GET_GOAL_DETAIL,
    payload
});

export const getGoalDetailSuccess = payload => ({
    type: GET_GOAL_DETAIL_SUCCESS,
    payload
});

export const getGoalDetailFailure = payload => ({
    type: GET_GOAL_DETAIL_FAILURE,
    payload
});

export const resetGoalDetail = () => ({
    type: RESET_GOAL_DETAIL
});