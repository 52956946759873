import React from 'react';
import PropTypes from 'prop-types';
import {X} from 'react-bootstrap-icons';

const DeleteConfirmDialog = ({isDeleteOpen, setDeleteOpen, handleDelete}) => {
	return (
		<div className='delete-dialog'>
			<div className={isDeleteOpen ? 'modal fade show d-block' : 'd-none'}>
				<div className='modal-dialog modal-dialog-centered' role='document'>
					<div className='modal-content'>
						<X
							width='2em'
							height='2em'
							className='bi bi-x cursor-pointer'
							onClick={() => setDeleteOpen(false)}
						/>
						<div className='px-lg-5 py-4'>
							<p className='text-center f-22'>Confirm</p>
							<p className='text-center f-16'>
								Are you sure you want to delete this record?
							</p>
							<div className='text-center'>
								<button
									className='btn btn-secondary px-4 py-2'
									onClick={() => setDeleteOpen(false)}>
									Cancel
								</button>
								<button
									className='btn btn-danger px-4 py-2 ml-2'
									onClick={() => handleDelete()}>
									Ok
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

DeleteConfirmDialog.propTypes = {
	isDeleteOpen: PropTypes.bool,
	setDeleteOpen: PropTypes.func,
	handleDelete: PropTypes.func,
};

export default DeleteConfirmDialog;
