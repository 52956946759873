import {
    ADD_BRANDING_FOLDER,
    ADD_BRANDING_FOLDER_FAILURE,
    ADD_BRANDING_FOLDER_SUCCESS,
    CREATE_NEW_MEDIA,
    CREATE_NEW_MEDIA_FAILURE,
    CREATE_NEW_MEDIA_SUCCESS,
    DELETE_FILE,
    DELETE_FILE_FAILURE,
    DELETE_FILE_SUCCESS,
    DELETE_FOLDER,
    DELETE_FOLDER_FAILURE,
    DELETE_FOLDER_SUCCESS,
    GET_BRANDING_LIST,
    GET_BRANDING_LIST_FAILURE,
    GET_BRANDING_LIST_SUCCESS
} from '../action/types';

const INIT_STATE = {
    loading: false,
    brandingList: {},
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_BRANDING_LIST:
            return {...state, loading: true};
        case GET_BRANDING_LIST_SUCCESS:
            return {...state, loading: false, brandingList: action.payload};
        case GET_BRANDING_LIST_FAILURE:
            return {...state, loading: false, brandingList: action.payload};
        case ADD_BRANDING_FOLDER:
            return {...state, loading: true};
        case ADD_BRANDING_FOLDER_SUCCESS:
            return {...state, loading: false};
        case ADD_BRANDING_FOLDER_FAILURE:
            return {...state, loading: false};
        case DELETE_FOLDER:
            return {...state, loading: true};
        case DELETE_FOLDER_SUCCESS:
            return {...state, loading: false};
        case DELETE_FOLDER_FAILURE:
            return {...state, loading: false};
        case DELETE_FILE:
            return {...state, loading: true};
        case DELETE_FILE_SUCCESS:
            return {...state, loading: false};
        case DELETE_FILE_FAILURE:
            return {...state, loading: false};
        case CREATE_NEW_MEDIA:
            return {...state, loading: true};
        case CREATE_NEW_MEDIA_SUCCESS:
            return {...state, loading: false};
        case CREATE_NEW_MEDIA_FAILURE:
            return {...state, loading: false};
        default:
            return state;
    }
};
