import React from "react";
import { all, takeEvery, put, call } from 'redux-saga/effects';
import {toast} from 'react-toastify';

import {
    CREATE_OR_EDIT_ATHLETE,
    GET_ATHLETES_LIST,
    DELETE_ATHLETE,
    GET_ATHLETE_DETAIL,
    GET_ATHLETE_DASHBOARD_DATA,
    SEND_MESSAGE
} from '../action/types';
import {
    getAthletesListSuccess,
    getAthletesListFailure,
    deleteAthleteSuccess,
    deleteAthleteFailure,
    getAthletesList,
    createOrEditAthletesSuccess,
    createOrEditAthletesFailure,
    getAthleteDetailSuccess,
    getAthleteDetailFailure,
    resetAthleteDetail,
    getAthleteDashboardDataSuccess,
    getAthleteDashboardDataFailure,
    sendMessageSuccess,
    sendMessageFailure
} from '../action';
import API from '../../utils/api';
import {ErrorToast, SuccessToast} from "../../utils/helper";

function* createOrEditAthleteRequest(action) {
    const { firstName, lastName, address, postal, city, email, sport, position,
        currentClub, website, facebookId, twitterId, userImg} = action.payload.form;
    const url = action.payload.id ? 'athletes/edit' : 'athletes/add';
    const bodyFormData = new FormData();
    action.payload.id && bodyFormData.append('id', action.payload.id);
    bodyFormData.append('first_name', firstName);
    bodyFormData.append('last_name', lastName);
    bodyFormData.append('current_club', currentClub);
    bodyFormData.append('postal', postal);
    bodyFormData.append('city', city);
    bodyFormData.append('sport', sport);
    bodyFormData.append('position', position);
    bodyFormData.append('address', address);
    bodyFormData.append('email', email);
    bodyFormData.append('website', website);
    bodyFormData.append('facebook_id', facebookId);
    bodyFormData.append('twitter_id', twitterId);
    try {
        const res = yield API.post(url, bodyFormData);
        if(res.data.code === 0) {
            yield put(createOrEditAthletesFailure());
            yield toast.error(<ErrorToast msg={res.data.message}/>);
        }else if(res.data.meta.code === 1) {
            if(userImg) {
                const bodyFormDataImg = new FormData();
                bodyFormDataImg.append('file', userImg);
                bodyFormDataImg.append('id', res.data.data.id);
                yield API.post('athletes/photo', bodyFormDataImg);
            }
            yield put(createOrEditAthletesSuccess());
            yield put(getAthletesList());
            yield call(action.payload.callback);
            yield toast.success(<SuccessToast msg={res.data.meta.message}/>);
            yield put(resetAthleteDetail());
        } else if(res.data.meta.code === 0) {
            yield put(createOrEditAthletesFailure());
            yield toast.error(<ErrorToast msg={res.data.meta.message}/>);
        }
    }catch (e) {
        yield put(createOrEditAthletesFailure());
        yield toast.error(<ErrorToast msg='Something went wrong.'/>);
    }
}

function* getAthletesListRequest() {
    try{
        const res = yield API.get('athletes');
        yield put(getAthletesListSuccess(res.data));
    }catch (e) {
        yield put(getAthletesListFailure(e));
    }
}

function* deleteAthleteRequest(action) {
    try {
        const res = yield API.delete(`athletes/${action.payload.id}`);
        if(res.data.meta.code === 1) {
            yield put(deleteAthleteSuccess());
            yield call(action.payload.calBack);
            yield toast.success(<SuccessToast msg={res.data.meta.message}/>);
            yield put(getAthletesList());
        }else if(res.data.meta.code === 0) {
            yield put(deleteAthleteFailure());
            yield toast.error(<ErrorToast msg={res.data.meta.message}/>);
        }
    } catch (e) {
        yield put(deleteAthleteFailure());
    }
}

function* getAthleteDetailRequest(action) {
    try{
        const res = yield API.get(`athletes/${action.payload}`);
        yield put(getAthleteDetailSuccess(res.data));
    }catch (e) {
        yield put(getAthleteDetailFailure(e));
    }
}

function* getAthleteDashboardDataRequest(action) {
    try{
        const res = yield API.get(`athlete-dashboard/${action.payload}`);
        if(res.data.meta.code === 1) {
            yield put(getAthleteDashboardDataSuccess(res.data.data))
        } else {
            yield put(getAthleteDashboardDataFailure(res.data))
        }
    }catch (e) {
        yield put(getAthleteDashboardDataFailure(e))
    }
}

function* sendMessageRequest(action){
    try{
        const bodyFormData = new FormData();
        bodyFormData.append('content', action.payload);
        const res = yield API.post('dashboard/sendMessage',bodyFormData);
        if(res.data.meta.code === 1) {
            yield put(sendMessageSuccess());
            yield toast.success(<SuccessToast msg={res.data.meta.message}/>);
        } else {
            yield put(sendMessageFailure());
            yield toast.error(<ErrorToast msg={res.data.meta.message}/>);
        }
    } catch (e) {
        yield put(sendMessageFailure());
        yield toast.error(<ErrorToast msg='Something went wrong.'/>);
    }
}

/*Watcher Functions for Saga*/
export function* watchCreateOrEditAthleteAPI() {
    yield takeEvery(CREATE_OR_EDIT_ATHLETE, createOrEditAthleteRequest);
}

export function* watchGetAthletesListAPI() {
    yield takeEvery(GET_ATHLETES_LIST, getAthletesListRequest);
}

export function* watchDeleteAthleteAPI() {
    yield takeEvery(DELETE_ATHLETE, deleteAthleteRequest)
}

export function* watchAthleteDetailAPI() {
    yield takeEvery(GET_ATHLETE_DETAIL, getAthleteDetailRequest);
}

export function* watchAthleteDashboardDataAPI() {
    yield takeEvery(GET_ATHLETE_DASHBOARD_DATA, getAthleteDashboardDataRequest);
}

export function* watchSendMessageAPI() {
    yield takeEvery(SEND_MESSAGE, sendMessageRequest);
}

export default function* rootSaga() {
    yield all([
        watchCreateOrEditAthleteAPI(),
        watchGetAthletesListAPI(),
        watchDeleteAthleteAPI(),
        watchAthleteDetailAPI(),
        watchAthleteDashboardDataAPI(),
        watchSendMessageAPI()
    ]);
}