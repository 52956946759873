import React from "react";
import {all, call, put, takeEvery} from 'redux-saga/effects';
import {toast} from 'react-toastify';

import {
    DELETE_EXTERNAL_USER,
    GET_EXTERNAL_USER_LIST,
    CREATE_OR_EDIT_EXTERNAL_USER,
    GET_EXTERNAL_USER_DETAIL
} from '../action/types';
import API from "../../utils/api";
import {
    deleteUserFailure,
    deleteUserSuccess,
    getUserListFailure,
    getUserListSuccess,
    getUsersList,
    getExternalUserDetailSuccess,
    getExternalUserDetailFailure,
    createOrEditExternalUserSuccess,
    createOrEditExternalUserFailure,
    resetExternalUserDetail,
} from "../action";
import {ErrorToast, SuccessToast} from "../../utils/helper";

function* getUsersListRequest() {
    try {
        const res = yield API.get('externals');
        yield put(getUserListSuccess(res.data));
    } catch (e) {
        yield put(getUserListFailure(e));
    }
}

function* deleteUserRequest(action) {
    try {
        const res = yield API.delete(`externals/${action.payload.id}`);
        if (res.data.meta.code === 1) {
            yield put(deleteUserSuccess());
            yield call(action.payload.calBack);
            yield toast.success(<SuccessToast msg={res.data.meta.message}/>);
            yield put(getUsersList());
        } else if (res.data.mete.code === 0) {
            yield put(deleteUserFailure());
            yield toast.error(<ErrorToast msg={res.data.meta.message}/>);
        }
    } catch (e) {
        yield put(deleteUserFailure());
    }
}

function* getExternalUserDetailRequest(action) {
    try{
        const res = yield API.get(`externals/${action.payload}`);
        yield put(getExternalUserDetailSuccess(res.data));
    }catch (e) {
        yield put(getExternalUserDetailFailure(e));
    }
}

function* createOrEditExternalUserRequest(action) {
    const { firstName, lastName, address, postal, city, email, profession,
        club, website, athletes, permission, userImg} = action.payload.form;
    const url = action.payload.id ? 'externals/edit' : 'externals/add';
    const bodyFormData = new FormData();
    action.payload.id && bodyFormData.append('id', action.payload.id);
    bodyFormData.append('first_name', firstName);
    bodyFormData.append('last_name', lastName);
    bodyFormData.append('current_club', club);
    bodyFormData.append('postal', postal);
    bodyFormData.append('city', city);
    bodyFormData.append('position', profession);
    bodyFormData.append('address', address);
    bodyFormData.append('email', email);
    bodyFormData.append('website', website);
    if(athletes.length > 0) {
        athletes.map(itm =>{
            if(permission.hasOwnProperty(itm)) {
                bodyFormData.append('athletes[]', itm)
            }
            return true;
        })
    }
    Object.keys(permission) &&
    Object.keys(permission).length > 0 &&
    Object.keys(permission).map(itm => Object.values(permission[itm]).map(item => bodyFormData.append(`permissions[${itm}][]`, item)));
    try {
        const res = yield API.post(url, bodyFormData);
        if(res.data.code === 0) {
            yield toast.error(<ErrorToast msg={res.data.meta.message}/>);
            yield put(createOrEditExternalUserFailure());
        } else if(res.data.meta.code === 1) {
            if(userImg) {
                const bodyFormDataImg = new FormData();
                bodyFormDataImg.append('file', userImg);
                bodyFormDataImg.append('id', res.data.data.id);
                yield API.post('externals/photo', bodyFormDataImg);
            }
            yield put(createOrEditExternalUserSuccess());
            yield put(getUsersList());
            yield call(action.payload.callback);
            yield toast.success(<SuccessToast msg={res.data.meta.message}/>);
            yield put(resetExternalUserDetail());
        } else if(res.data.meta.code === 0) {
            yield toast.error(<ErrorToast msg={res.data.meta.message}/>);
            yield put(createOrEditExternalUserFailure());
        }
    } catch (e) {
        yield put(createOrEditExternalUserFailure());
        yield toast.error(<ErrorToast msg='Something went wrong.'/>);
    }
}

export function* watchCreateOrEditExternalUserAPI() {
    yield takeEvery(CREATE_OR_EDIT_EXTERNAL_USER, createOrEditExternalUserRequest);
}

export function* watchGetUserListAPI() {
    yield takeEvery(GET_EXTERNAL_USER_LIST, getUsersListRequest);
}

export function* watchDeleteUserAPI() {
    yield takeEvery(DELETE_EXTERNAL_USER, deleteUserRequest)
}

export function* watchExternalUserDetailAPI() {
    yield takeEvery(GET_EXTERNAL_USER_DETAIL, getExternalUserDetailRequest);
}

export default function* rootSaga() {
    yield all([
        watchGetUserListAPI(),
        watchDeleteUserAPI(),
        watchExternalUserDetailAPI(),
        watchCreateOrEditExternalUserAPI()
    ]);
}
