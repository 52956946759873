// =====================Login===========================

export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';
export const USER_DATA_REMOVE = 'USER_DATA_REMOVE';

export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const LOGOUT_USER_FAILURE = 'LOGOUT_USER_FAILURE';

// ================== Goals Create Or Edit ================

export const CREATE_OR_EDIT_GOALS = 'CREATE_OR_EDIT_GOALS';
export const CREATE_OR_EDIT_GOALS_SUCCESS = 'CREATE_OR_EDIT_GOALS_SUCCESS';
export const CREATE_OR_EDIT_GOALS_FAILURE = 'CREATE_OR_EDIT_GOALS_FAILURE';

export const VIEW_GOALS_LIST = 'VIEW_GOALS_LIST';
export const VIEW_GOALS_LIST_SUCCESS = 'VIEW_GOALS_LIST_SUCCESS';
export const VIEW_GOALS_LIST_FAILURE = 'VIEW_GOALS_LIST_FAILURE';

export const COMPLETED_INCOMPLETED_GOAL = 'COMPLETED_INCOMPLETED_GOAL';
export const COMPLETED_INCOMPLETED_GOAL_SUCCESS = 'COMPLETED_INCOMPLETED_GOAL_SUCCESS';
export const COMPLETED_INCOMPLETED_GOAL_FAILURE = 'COMPLETED_INCOMPLETED_GOAL_FAILURE';

export const DELETE_GOAL = 'DELETE_GOAL';
export const DELETE_GOAL_SUCCESS = 'DELETE_GOAL_SUCCESS';
export const DELETE_GOAL_FAILURE = 'DELETE_GOAL_FAILURE';

export const GET_GOAL_DETAIL = 'GET_GOAL_DETAIL';
export const GET_GOAL_DETAIL_SUCCESS = 'GET_GOAL_DETAIL_SUCCESS';
export const GET_GOAL_DETAIL_FAILURE = 'GET_GOAL_DETAIL_FAILURE';
export const RESET_GOAL_DETAIL = 'RESET_GOAL_DETAIL';

// ================== Athletes Create Or Edit ================

export const CREATE_OR_EDIT_ATHLETE = 'CREATE_OR_EDIT_ATHLETE';
export const CREATE_OR_EDIT_ATHLETE_SUCCESS = 'CREATE_OR_EDIT_ATHLETE_SUCCESS';
export const CREATE_OR_EDIT_ATHLETE_FAILURE = 'CREATE_OR_EDIT_ATHLETE_FAILURE';

export const GET_ATHLETES_LIST = 'GET_ATHLETES_LIST';
export const GET_ATHLETES_LIST_SUCCESS = 'GET_ATHLETES_LIST_SUCCESS';
export const GET_ATHLETES_LIST_FAILURE = 'GET_ATHLETES_LIST_FAILURE';

export const DELETE_ATHLETE = 'DELETE_ATHLETE';
export const DELETE_ATHLETE_SUCCESS = 'DELETE_ATHLETE_SUCCESS';
export const DELETE_ATHLETE_FAILURE = 'DELETE_ATHLETE_FAILURE';

export const GET_ATHLETE_DETAIL = 'GET_ATHLETE_DETAIL';
export const GET_ATHLETE_DETAIL_SUCCESS = 'GET_ATHLETE_DETAIL_SUCCESS';
export const GET_ATHLETE_DETAIL_FAILURE = 'GET_ATHLETE_DETAIL_FAILURE';
export const RESET_ATHLETE_DETAIL = 'RESET_ATHLETE_DETAIL';

export const GET_ATHLETE_DASHBOARD_DATA = 'GET_ATHLETE_DASHBOARD_DATA';
export const GET_ATHLETE_DASHBOARD_DATA_SUCCESS = 'GET_ATHLETE_DASHBOARD_DATA_SUCCESS';
export const GET_ATHLETE_DASHBOARD_DATA_FAILURE = 'GET_ATHLETE_DASHBOARD_DATA_FAILURE';

export const SEND_MESSAGE = 'SEND_MESSAGE';
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_FAILURE = 'SEND_MESSAGE_FAILURE';

// ================== ExternalUser ================

export const CREATE_OR_EDIT_EXTERNAL_USER = 'CREATE_OR_EDIT_EXTERNAL_USER';
export const CREATE_OR_EDIT_EXTERNAL_USER_SUCCESS = 'CREATE_OR_EDIT_EXTERNAL_USER_SUCCESS';
export const CREATE_OR_EDIT_EXTERNAL_USER_FAILURE = 'CREATE_OR_EDIT_EXTERNAL_USER_FAILURE';

export const GET_EXTERNAL_USER_LIST = 'GET_EXTERNAL_USER_LIST';
export const GET_EXTERNAL_USER_LIST_SUCCESS = 'GET_EXTERNAL_USER_LIST_SUCCESS';
export const GET_EXTERNAL_USER_LIST_FAILURE = 'GET_EXTERNAL_USER_LIST_FAILURE';

export const DELETE_EXTERNAL_USER = 'DELETE_EXTERNAL_USER';
export const DELETE_EXTERNAL_USER_SUCCESS = 'DELETE_EXTERNAL_USER_SUCCESS';
export const DELETE_EXTERNAL_USER_FAILURE = 'DELETE_EXTERNAL_USER_FAILURE';

export const GET_EXTERNAL_USER_DETAIL = 'GET_EXTERNAL_USER_DETAIL';
export const GET_EXTERNAL_USER_DETAIL_SUCCESS = 'GET_EXTERNAL_USER_DETAIL_SUCCESS';
export const GET_EXTERNAL_USER_DETAIL_FAILURE = 'GET_EXTERNAL_USER_DETAIL_FAILURE';
export const RESET_EXTERNAL_USER_DETAIL = 'RESET_EXTERNAL_USER_DETAIL';

// ================== Highlight ================

export const GET_HIGHLIGHTS_LIST = 'GET_HIGHLIGHTS_LIST';
export const GET_HIGHLIGHTS_LIST_SUCCESS = 'GET_HIGHLIGHTS_LIST_SUCCESS';
export const GET_HIGHLIGHTS_LIST_FAILURE = 'GET_HIGHLIGHTS_LIST_FAILURE';

export const CREATE_OR_EDIT_HIGHLIGHTS= 'CREATE_OR_EDIT_HIGHLIGHTS';
export const CREATE_OR_EDIT_HIGHLIGHTS_FAILURE = 'CREATE_OR_EDIT_HIGHLIGHTS_FAILURE';
export const CREATE_OR_EDIT_HIGHLIGHTS_SUCCESS = 'CREATE_OR_EDIT_HIGHLIGHTS_SUCCESS';

export const GET_HIGHLIGHTS_DETAIL= 'GET_HIGHLIGHTS_DETAIL';
export const GET_HIGHLIGHTS_DETAIL_FAILURE = 'GET_HIGHLIGHTS_DETAIL_FAILURE';
export const GET_HIGHLIGHTS_DETAIL_SUCCESS = 'GET_HIGHLIGHTS_DETAIL_SUCCESS';
export const RESET_HIGHLIGHTS_DETAIL = 'RESET_HIGHLIGHTS_DETAIL';

export const DELETE_HIGHLIGHTS = 'DELETE_HIGHLIGHTS';
export const DELETE_HIGHLIGHTS_FAILURE = 'DELETE_HIGHLIGHTS_FAILURE';
export const DELETE_HIGHLIGHTS_SUCCESS = 'DELETE_HIGHLIGHTS_SUCCESS';

//=============  Marketing    =================================

export const GET_MARKETING_LIST = 'GET_MARKETING_LIST';
export const GET_MARKETING_LIST_SUCCESS = 'GET_MARKETING_LIST_SUCCESS';
export const GET_MARKETING_LIST_FAILURE = 'GET_MARKETING_LIST_FAILURE';

export const CREATE_OR_EDIT_MARKETING = 'CREATE_OR_EDIT_MARKETING';
export const CREATE_OR_EDIT_MARKETING_SUCCESS = 'CREATE_OR_EDIT_MARKETING_SUCCESS';
export const CREATE_OR_EDIT_MARKETING_FAILURE = 'CREATE_OR_EDIT_MARKETING_FAILURE';

export const DELETE_MARKETING = 'DELETE_MARKETING';
export const DELETE_MARKETING_SUCCESS = 'DELETE_MARKETING_SUCCESS';
export const DELETE_MARKETING_FAILURE = 'DELETE_MARKETING_FAILURE';

export const GET_MARKETING_DETAIL = 'GET_MARKETING_DETAIL';
export const GET_MARKETING_DETAIL_SUCCESS = 'GET_MARKETING_DETAIL_SUCCESS';
export const GET_MARKETING_DETAIL_FAILURE = 'GET_MARKETING_DETAIL_FAILURE';
export const RESET_MARKETING_DETAIL = 'RESET_MARKETING_DETAIL';

//=============  Forgot password   =================================

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

//============ Branding ==================

export const GET_BRANDING_LIST = 'GET_BRANDING_LIST';
export const GET_BRANDING_LIST_SUCCESS = 'GET_BRANDING_LIST_SUCCESS';
export const GET_BRANDING_LIST_FAILURE = 'GET_BRANDING_LIST_FAILURE';

export const ADD_BRANDING_FOLDER = 'ADD_BRANDING_FOLDER';
export const ADD_BRANDING_FOLDER_SUCCESS = 'ADD_BRANDING_FOLDER_SUCCESS';
export const ADD_BRANDING_FOLDER_FAILURE = 'ADD_BRANDING_FOLDER_FAILURE';

export const DELETE_FOLDER = 'DELETE_FOLDER';
export const DELETE_FOLDER_SUCCESS = 'DELETE_FOLDER_SUCCESS';
export const DELETE_FOLDER_FAILURE = 'DELETE_FOLDER_FAILURE';

export const DELETE_FILE = 'DELETE_FILE';
export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS';
export const DELETE_FILE_FAILURE = 'DELETE_FILE_FAILURE';

export const CREATE_NEW_MEDIA = 'CREATE_NEW_MEDIA';
export const CREATE_NEW_MEDIA_SUCCESS = 'CREATE_NEW_MEDIA_SUCCESS';
export const CREATE_NEW_MEDIA_FAILURE = 'CREATE_NEW_MEDIA_FAILURE';

// ================== LEGAL ================

export const UPLOAD_LEGAL = 'UPLOAD_LEGAL';
export const UPLOAD_LEGAL_SUCCESS = 'UPLOAD_LEGAL_SUCCESS';
export const UPLOAD_LEGAL_FAILURE = 'UPLOAD_LEGAL_FAILURE';

export const GET_LEGAL_LIST_FAILURE = 'GET_LEGAL_LIST_FAILURE';
export const GET_LEGAL_LIST_SUCCESS = 'GET_LEGAL_LIST_SUCCESS';
export const GET_LEGAL_LIST = 'GET_LEGAL_LIST';


export const DELETE_LEGAL = 'DELETE_LEGAL';
export const DELETE_LEGAL_SUCCESS = 'DELETE_LEGAL_SUCCESS';
export const DELETE_LEGAL_FAILURE = 'DELETE_LEGAL_FAILURE';

export const GET_LEGAL_DETAIL = 'GET_LEGAL_DETAIL';
export const GET_LEGAL_DETAIL_SUCCESS = 'GET_LEGAL_DETAIL_SUCCESS';
export const GET_LEGAL_DETAIL_FAILURE = 'GET_LEGAL_DETAIL_FAILURE';
export const RESET_LEGAL_DETAIL = 'RESET_LEGAL_DETAIL';


//================ VisionBoard ==============

export const GET_VISIONBOARD_LIST = 'GET_VISIONBOARD_LIST';
export const GET_VISIONBOARD_LIST_SUCCESS = 'GET_VISIONBOARD_LIST_SUCCESS';
export const GET_VISIONBOARD_LIST_FAILURE = 'GET_VISIONBOARD_LIST_FAILURE';

export const UPLOAD_VISIONBOARD = 'UPLOAD_VISIONBOARD';
export const UPLOAD_VISIONBOARD_SUCCESS = 'UPLOAD_VISIONBOARD_SUCCESS';
export const UPLOAD_VISIONBOARD_FAILURE = 'UPLOAD_VISIONBOARD_FAILURE';

//======================== Calender ===============================

export const GET_CALENDAR_EVENT = 'GET_CALENDAR_EVENT';
export const GET_CALENDAR_EVENT_SUCCESS = 'GET_CALENDAR_EVENT_SUCCESS';
export const GET_CALENDAR_EVENT_FAILURE = 'GET_CALENDAR_EVENT_FAILURE';

export const RESPOND__EVENT = 'RESPOND__EVENT';
export const RESPOND__EVENT_SUCCESS = 'RESPOND__EVENT_SUCCESS';
export const RESPOND_EVENT_FAILURE = 'RESPOND_EVENT_FAILURE';
export const RESET_EVENT_DETAIL = 'RESET_EVENT_DETAIL';

export const DELETE_CALENDAR_EVENT = 'DELETE_CALENDAR_EVENT';
export const DELETE_CALENDAR_EVENT_SUCCESS = 'DELETE_CALENDAR_EVENT_SUCCESS';
export const DELETE_CALENDAR_EVENT_FAILURE = 'DELETE_CALENDAR_EVENT_FAILURE';

export const ADD_EDIT_CALENDAR_EVENT = 'ADD_EDIT_CALENDAR_EVENT';
export const ADD_EDIT_CALENDAR_EVENT_SUCCESS = 'ADD_EDIT_CALENDAR_EVENT_SUCCESS';
export const ADD_EDIT_CALENDAR_EVENT_FAILURE = 'ADD_EDIT_CALENDAR_EVENT_FAILURE';

export const GET_EVENT_DETAILS_BY_ID = "GET_EVENT_DETAILS_BY_ID";
export const GET_EVENT_DETAILS_BY_ID_SUCCESS = "GET_EVENT_DETAILS_BY_ID_SUCCESS";
export const GET_EVENT_DETAILS_BY_ID_FAILURE = "GET_EVENT_DETAILS_BY_ID_FAILURE";
