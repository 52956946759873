import {
    CREATE_OR_EDIT_GOALS,
    CREATE_OR_EDIT_GOALS_SUCCESS,
    CREATE_OR_EDIT_GOALS_FAILURE,

    VIEW_GOALS_LIST,
    VIEW_GOALS_LIST_SUCCESS,
    VIEW_GOALS_LIST_FAILURE,

    COMPLETED_INCOMPLETED_GOAL,
    COMPLETED_INCOMPLETED_GOAL_SUCCESS,
    COMPLETED_INCOMPLETED_GOAL_FAILURE,

    DELETE_GOAL,
    DELETE_GOAL_SUCCESS,
    DELETE_GOAL_FAILURE,

    GET_GOAL_DETAIL,
    GET_GOAL_DETAIL_SUCCESS,
    GET_GOAL_DETAIL_FAILURE,
    RESET_GOAL_DETAIL,

} from '../action/types';

const INIT_STATE = {
    loading: false,
    goalsList: [],
    goalDetail: {}
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case CREATE_OR_EDIT_GOALS:
            return { ...state, loading: true };
        case CREATE_OR_EDIT_GOALS_SUCCESS:
            return { ...state, loading: false };
        case CREATE_OR_EDIT_GOALS_FAILURE:
            return { ...state, loading: false };

        case VIEW_GOALS_LIST:
            return { ...state, loading: true};
        case VIEW_GOALS_LIST_SUCCESS:
            return { ...state, loading: false, goalsList: action.payload};
        case VIEW_GOALS_LIST_FAILURE:
            return { ...state, loading: false, goalsList: action.payload};

        case COMPLETED_INCOMPLETED_GOAL:
            return { ...state, loading: true};
        case COMPLETED_INCOMPLETED_GOAL_SUCCESS:
            return { ...state, loading: false};
        case COMPLETED_INCOMPLETED_GOAL_FAILURE:
            return { ...state, loading: false};

        case DELETE_GOAL:
            return { ...state, loading: true};
        case DELETE_GOAL_SUCCESS:
            return { ...state, loading: false};
        case DELETE_GOAL_FAILURE:
            return { ...state, loading: false};

        case GET_GOAL_DETAIL:
            return { ...state, loading: true };
        case GET_GOAL_DETAIL_SUCCESS:
            return { ...state, loading: false, goalDetail: action.payload };
        case GET_GOAL_DETAIL_FAILURE:
            return { ...state, loading: false, goalDetail: action.payload };
        case RESET_GOAL_DETAIL:
            return { ...state, goalDetail: {} };

        default:
            return state;
    }
};
