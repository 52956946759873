import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILURE,
    USER_DATA_REMOVE,

    FORGOT_PASSWORD,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAILURE,

    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,

    LOGOUT_USER,
    LOGOUT_USER_SUCCESS,
    LOGOUT_USER_FAILURE
} from '../action/types';

const INIT_STATE = {
    loading: false,
    userData: {}
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOGIN_USER:
            return { ...state, loading: true };
        case LOGIN_USER_SUCCESS:
            return { ...state, loading: false, userData: action.payload };
        case LOGIN_USER_FAILURE:
            return { ...state, loading: false, userData: action.payload };
        case FORGOT_PASSWORD:
            return { ...state, loading: true };
        case FORGOT_PASSWORD_SUCCESS:
            return { ...state, loading: false };
        case FORGOT_PASSWORD_FAILURE:
            return { ...state, loading: false };
        case RESET_PASSWORD:
            return { ...state, loading: true };
        case RESET_PASSWORD_SUCCESS:
            return { ...state, loading: false };
        case RESET_PASSWORD_FAILURE:
            return { ...state, loading: false };
        case USER_DATA_REMOVE:
            return { ...state, loading: false, userData: {} };
        case LOGOUT_USER:
            return { ...state, loading: true };
        case LOGOUT_USER_SUCCESS:
            return { ...state, loading: false };
        case LOGOUT_USER_FAILURE:
            return { ...state, loading: false };
        default:
            return state;
    }
};