import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import {useWindowSize} from '../../utils/helper';
import {Calendar4Event, ChevronLeft} from 'react-bootstrap-icons';

const Banner = ({
	title,
	RedirectTo,
	buttonText,
	backUrl,
	backText,
	count,
	goalRedirect,
	goalBannerText,
	isGoal,
	isBranding,
	setCreateOrEditGoalOpen,
	classNames,
	backClick,
	isMarketingOrHighlight,
	buttonClick,
	setAddOpen,
	isDeteteFolder,
	handleDelete,
	handleDownloadZip,
	setIsCreateNewEvent,
	isEvent,
	isGreen,
}) => {
	const [width] = useWindowSize();

	return (
		<div className='bannerBox'>
			<div className='overlay'>
				{width <= 767 && backUrl && backText && (
					<div className='d-flex justify-content-between float-lg-left mt-lg-5 ml-md-5 mr-md-5 mt-md-0 ml-2 mr-2 mt-0 mb-2 pt-3 pt-lg-0 blackBg'>
						<Link
							className='m-2 text-white'
							to={backUrl}
							onClick={() => backClick()}>
							<ChevronLeft size={16} />
							<span>Back to {backText}</span>
						</Link>
					</div>
				)}
				<div
					className={`d-flex justify-content-between float-lg-right mt-lg-5 ml-md-5 mr-md-5 mb-md-5 mt-md-0 ml-2 mr-2 mt-0 mb-2 pt-3 pt-lg-0 ${
						!backUrl && 'blackBg'
					}`}>
					<Link
						className='d-flex align-items-center ml-lg-5 m-2 text-white'
						to='/calendar'>
						<Calendar4Event size={16}></Calendar4Event>
						<span className='ml-2'>Calendar</span>
					</Link>
				</div>
				<div className='bannerBottom'>
					<h1 className={`bannerHeading ${classNames}`}>
						{title} <br className='d-block d-lg-none' />
						{isGoal && (
							<Link
								to={goalRedirect}
								className='f-16 pl-lg-4 pl-0 text-decoration-none text-white'>
								{goalBannerText}
								{count > 0 && (
									<span className='rounded-pill pl-2 pr-2 ml-2 primary-bg-color'>
										{count}
									</span>
								)}
							</Link>
						)}
					</h1>
					<div className='banner-button d-flex justify-content-around font-weight-bold'>
						{isBranding && (
							<>
								<button
									className='customBtn btn f-16 cursor-pointer mx-1'
									onClick={setAddOpen}>
									Add folder
								</button>
								{isDeteteFolder && (
									<button
										className='customBtn btn f-16 cursor-pointer mx-1'
										onClick={handleDelete}>
										Delete folder
									</button>
								)}
								{width > 767 && (
									<button
										className='customBtn btn f-16 cursor-pointer mx-1'
										onClick={handleDownloadZip}>
										Download ZIP
									</button>
								)}
							</>
						)}
						{isGoal ? (
							<button
								className='btn customBtn f-16 cursor-pointer mx-1'
								onClick={() =>
									isEvent
										? setIsCreateNewEvent(true)
										: setCreateOrEditGoalOpen(true)
								}>
								{buttonText}
							</button>
						) : isMarketingOrHighlight ? (
							<button
								className={
									buttonText ? 'btn customBtn f-16 cursor-pointer mx-1' : 'mx-1'
								}
								onClick={(e) => buttonClick(e)}>
								{buttonText}
							</button>
						) : (
							<Link
								className={
									isGreen
										? 'greeBtn f-16 cursor-pointer mx-1'
										: 'customBtn mx-1 f-16 cursor-pointer'
								}
								to={RedirectTo ? RedirectTo : ''}
								onClick={() => backClick()}>
								{buttonText}
							</Link>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

Banner.propTypes = {
	title: PropTypes.string,
	RedirectTo: PropTypes.string,
	buttonText: PropTypes.string,
	backUrl: PropTypes.string,
	backText: PropTypes.string,
	isBranding: PropTypes.bool,
	isGoal: PropTypes.bool,
	setCreateOrEditGoalOpen: PropTypes.func,
	goalRedirect: PropTypes.string,
	goalBannerText: PropTypes.string,
	count: PropTypes.number,
	backClick: PropTypes.func,
	isMarketingOrHighlight: PropTypes.bool,
	buttonClick: PropTypes.func,
	setAddOpen: PropTypes.func,
	isDeteteFolder: PropTypes.bool,
	handleDelete: PropTypes.func,
	handleDownloadZip: PropTypes.func,
	setIsCreateNewEvent: PropTypes.func,
	isEvent: PropTypes.bool,
};

export default Banner;
