import { all } from 'redux-saga/effects';

import Login from './loginSaga';
import Goals from './goalsSaga';
import Athlete from './athleteSaga';
import Legal from './legalsaga'
import ExternalUser from './externalUserSaga';
import Highlights from './highlightsSaga';
import Marketing from './marketingSaga';
import Branding from './brandingSaga';
import Visionboard from './visionboardSaga';
import Calendar from './calendarSaga';

export default function* rootSaga() {
    yield all([
        Login(),
        Goals(),
        Athlete(),
        ExternalUser(),
        Legal(),
        Marketing(),
        Highlights(),
        Branding(),
        Visionboard(),
        Calendar()
    ]);
}
