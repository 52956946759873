import {
    GET_VISIONBOARD_LIST,
    GET_VISIONBOARD_LIST_SUCCESS,
    GET_VISIONBOARD_LIST_FAILURE,
    UPLOAD_VISIONBOARD,
    UPLOAD_VISIONBOARD_SUCCESS, UPLOAD_VISIONBOARD_FAILURE,
} from './types'

export const getVisionboardList = (payload) => ({
    type: GET_VISIONBOARD_LIST,
    payload
});

export const getVisionboardListSuccess = (payload) => ({
    type: GET_VISIONBOARD_LIST_SUCCESS,
    payload
});

export const getVisionboardListFailure = payload => ({
    type: GET_VISIONBOARD_LIST_FAILURE,
    payload
});


export const uploadVISIONBOARD = payload => ({
    type: UPLOAD_VISIONBOARD,
    payload
});

export const uploadVISIONBOARDSuccess = payload => ({
    type: UPLOAD_VISIONBOARD_SUCCESS,
    payload
});

export const uploadVISIONBOARDFailure = payload => ({
    type: UPLOAD_VISIONBOARD_FAILURE,
    payload
});
