import {
    DELETE_LEGAL,
    DELETE_LEGAL_FAILURE,
    DELETE_LEGAL_SUCCESS,
    GET_LEGAL_DETAIL,
    GET_LEGAL_DETAIL_FAILURE,
    GET_LEGAL_DETAIL_SUCCESS,
    GET_LEGAL_LIST,
    GET_LEGAL_LIST_FAILURE,
    GET_LEGAL_LIST_SUCCESS,
    UPLOAD_LEGAL,
    UPLOAD_LEGAL_FAILURE,
    UPLOAD_LEGAL_SUCCESS
} from "./types";

export const uploadLegal = payload => ({
    type: UPLOAD_LEGAL,
    payload
});

export const uploadLegalSuccess = payload => ({
    type: UPLOAD_LEGAL_SUCCESS,
    payload
});

export const uploadLegalFailure = payload => ({
    type: UPLOAD_LEGAL_FAILURE,
    payload
});

export const getLegalList = (payload) => ({
    type: GET_LEGAL_LIST,
    payload
})

export const getLegalListSuccess = payload => ({
    type: GET_LEGAL_LIST_SUCCESS,
    payload
})


export const getLegalDetail = (payload) => ({
    type: GET_LEGAL_DETAIL,
    payload
});

export const getLegalDetailSuccess = payload => ({
    type: GET_LEGAL_DETAIL_SUCCESS,
    payload
})

export const getLegalDetailFailure = payload => ({
    type: GET_LEGAL_DETAIL_FAILURE,
    payload
})

export const getLegalListFailure = payload => ({
    type: GET_LEGAL_LIST_FAILURE,
    payload
})

export const deleteLEGAL = payload => ({
    type: DELETE_LEGAL,
    payload
});

export const deleteLEGALSuccess = () => ({
    type: DELETE_LEGAL_SUCCESS
});

export const deleteLEGALFailure = () => ({
    type: DELETE_LEGAL_FAILURE
});
