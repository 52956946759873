import React from "react";
import { all, takeEvery, put, call } from 'redux-saga/effects';
import {toast} from "react-toastify";

import {GET_BRANDING_LIST, ADD_BRANDING_FOLDER, DELETE_FOLDER, DELETE_FILE, CREATE_NEW_MEDIA} from '../action/types';
import API from '../../utils/api';
import {
    getBrandingListSuccess,
    getBrandingListFailure,
    addBrandingFolderSuccess,
    addBrandingFolderFailure,
    getBrandingList,
    deleteFolderFailure,
    deleteFolderSuccess,
    deleteFileSuccess,
    deleteFileFailure, createNewMediaSuccess, createNewMediaFailure,
} from '../action';
import {getUser} from "../../utils/helper";
import {ErrorToast, SuccessToast} from "../../utils/helper";

function* getBrandingListRequest(action) {
    try{
        let url;
        if(action.payload.athlete_id && action.payload.folder) {
            url = `branding?athlete_id=${action.payload.athlete_id}&folder=${action.payload.folder}`;
        } else if(action.payload.athlete_id) {
            url = `branding?athlete_id=${action.payload.athlete_id}`;
        } else if(action.payload.folder) {
            url = `branding?folder=${action.payload.folder}`;
        } else {
            url = 'branding'
        }
        const res = yield API.get(url);
        yield put(getBrandingListSuccess(res.data.data));
    }catch (e) {
        yield put(getBrandingListFailure(e));
    }
}

function* addBrandingFolderRequest(action) {
    try{
        const bodyFormData = new FormData();
        bodyFormData.append('name', `${action.payload.folder}/${action.payload.name}`);
        const res = yield API.post('branding/addFolder', bodyFormData)
        if(res.data.meta.code === 1) {
            yield put(getBrandingList({
                folder: action.payload.folder
            }));
            yield put(addBrandingFolderSuccess());
            yield call(action.payload.callBack);
            yield toast.success(<SuccessToast msg={res.data.meta.message}/>);
        }else if(res.data.meta.code ===0) {
            yield put(addBrandingFolderFailure());
            yield toast.error(<ErrorToast msg={res.data.meta.message}/>);
        }
    }catch (e) {
        yield put(addBrandingFolderFailure());
        yield toast.error(<ErrorToast msg='Something went wrong.'/>);
    }
}

function* deleteFolderRequest(action) {
    try {
        const bodyFormData = new FormData();
        bodyFormData.append('folder', action.payload.folder);
        const res = yield API.post('branding/deleteFolder', bodyFormData);
        if(res.data.meta.code === 1) {
            yield put(deleteFolderSuccess());
            yield put(getBrandingList({
                folder: action.payload.path
            }));
            yield call(action.payload.callBack);
            yield call(action.payload.callBackDelete);
            yield toast.success(<SuccessToast msg={res.data.meta.message}/>);
        } else if(res.data.meta.code === 0) {
            yield put(deleteFolderFailure());
            yield call(action.payload.callBackDelete);
            yield toast.error(<ErrorToast msg={res.data.meta.message}/>);
        }
    } catch (e) {
        yield put(deleteFolderFailure());
        yield toast.error(<ErrorToast msg='Something went wrong.'/>);
    }
}

function* deleteFileRequest(action) {
    try{
        const bodyFormData = new FormData();
        bodyFormData.append('file_id', action.payload.id);
        const res = yield API.post('branding/update', bodyFormData)
        if(res.data.meta.code === 1) {
            if(!JSON.parse(getUser()).is_admin) {
                yield put(getBrandingList({
                    folder: action.payload.folder,
                    athlete_id: JSON.parse(getUser()).id
                }));
            } else {
                yield put(getBrandingList({
                    folder: action.payload.folder
                }));
            }
            yield put(deleteFileSuccess());
            yield call(action.payload.callBack);
            yield toast.success(<SuccessToast msg={res.data.meta.message}/>);
        }else if(res.data.meta.code ===0) {
            yield put(deleteFileFailure());
            yield toast.error(<ErrorToast msg={res.data.meta.message}/>);
        }
    }catch (e) {
        yield put(deleteFileFailure());
        yield toast.error(<ErrorToast msg='Something went wrong.'/>);
    }
}

function* createNewMediaRequest(action) {
    const bodyFormData = new FormData();
    bodyFormData.append('athlete', action.payload.id);
    action.payload.folder && bodyFormData.append('folder', action.payload.folder);
    action.payload.files.forEach((item) => {
        bodyFormData.append('file[]', item.file);
    });
    try{
        const res = yield API.post('branding/addFile', bodyFormData);
        if (res.data.meta.code === 1) {
            yield put(createNewMediaSuccess());
            yield toast.success(<SuccessToast msg={res.data.meta.message}/>);
            yield call(action.payload.callBack);
            yield put(getBrandingList({
                folder: action.payload.folder
            }));
        } else {
            yield put(createNewMediaFailure());
            yield toast.error(<ErrorToast msg={res.data.meta.message}/>);
        }
    } catch (e) {
        yield put(createNewMediaFailure());
        yield toast.error(<ErrorToast msg='Something went wrong.'/>);
    }
}

export function* watchBrandingListAPI() {
    yield takeEvery(GET_BRANDING_LIST, getBrandingListRequest);
}

export function* watchAddBrandingAPI() {
    yield takeEvery(ADD_BRANDING_FOLDER, addBrandingFolderRequest);
}

export function* watchDeleteFolderAPI() {
    yield takeEvery(DELETE_FOLDER, deleteFolderRequest)
}

export function* watchDeleteFileAPI() {
    yield takeEvery(DELETE_FILE, deleteFileRequest);
}

export function* watchCreateNewMediaAPI() {
    yield takeEvery(CREATE_NEW_MEDIA, createNewMediaRequest)
}

export default function* rootSaga() {
    yield all([
        watchBrandingListAPI(),
        watchAddBrandingAPI(),
        watchDeleteFolderAPI(),
        watchDeleteFileAPI(),
        watchCreateNewMediaAPI()
    ]);
}