import {
    CREATE_OR_EDIT_ATHLETE,
    CREATE_OR_EDIT_ATHLETE_SUCCESS,
    CREATE_OR_EDIT_ATHLETE_FAILURE,

    GET_ATHLETES_LIST,
    GET_ATHLETES_LIST_SUCCESS,
    GET_ATHLETES_LIST_FAILURE,

    DELETE_ATHLETE,
    DELETE_ATHLETE_SUCCESS,
    DELETE_ATHLETE_FAILURE,

    GET_ATHLETE_DETAIL,
    GET_ATHLETE_DETAIL_SUCCESS,
    GET_ATHLETE_DETAIL_FAILURE,
    RESET_ATHLETE_DETAIL,

    GET_ATHLETE_DASHBOARD_DATA,
    GET_ATHLETE_DASHBOARD_DATA_SUCCESS,
    GET_ATHLETE_DASHBOARD_DATA_FAILURE,

    SEND_MESSAGE,
    SEND_MESSAGE_SUCCESS,
    SEND_MESSAGE_FAILURE,
} from './types';

/*Action Creator for Athlete Create Or Edit*/
export const createOrEditAthletes = payload => ({
    type: CREATE_OR_EDIT_ATHLETE,
    payload
});

export const createOrEditAthletesSuccess = () => ({
    type: CREATE_OR_EDIT_ATHLETE_SUCCESS
});

export const createOrEditAthletesFailure= () => ({
    type: CREATE_OR_EDIT_ATHLETE_FAILURE
});

/*Action Creator for Get Athlete List*/
export const getAthletesList = () => ({
    type: GET_ATHLETES_LIST
});

export const getAthletesListSuccess = (payload) => ({
   type: GET_ATHLETES_LIST_SUCCESS,
   payload
});

export const getAthletesListFailure = payload => ({
   type: GET_ATHLETES_LIST_FAILURE,
   payload
});

/*Action Creator for Delete Athlete*/
export const deleteAthlete = payload => ({
    type: DELETE_ATHLETE,
    payload
});

export const deleteAthleteSuccess = () => ({
    type: DELETE_ATHLETE_SUCCESS
});

export const deleteAthleteFailure = () => ({
   type: DELETE_ATHLETE_FAILURE
});

/*Action Creator for Get Particular Athlete */
export const getAthleteDetail = (payload) => ({
   type: GET_ATHLETE_DETAIL,
   payload
});

export const getAthleteDetailSuccess = payload => ({
   type: GET_ATHLETE_DETAIL_SUCCESS,
   payload
});

export const getAthleteDetailFailure = payload => ({
   type: GET_ATHLETE_DETAIL_FAILURE,
   payload
});

export const resetAthleteDetail = () => ({
    type: RESET_ATHLETE_DETAIL
});

/*Action Creator For Athlete Dashboard Data*/
export const getAthleteDashboardData = (payload) => ({
   type: GET_ATHLETE_DASHBOARD_DATA,
   payload
});

export const getAthleteDashboardDataSuccess = (payload) => ({
   type: GET_ATHLETE_DASHBOARD_DATA_SUCCESS,
   payload
});

export const getAthleteDashboardDataFailure = payload => ({
   type: GET_ATHLETE_DASHBOARD_DATA_FAILURE,
   payload
});

/*Action creator for Send Message*/
export const sendMessage = payload => ({
    type: SEND_MESSAGE,
    payload
});

export const sendMessageSuccess = () => ({
   type: SEND_MESSAGE_SUCCESS
});

export const sendMessageFailure = () => ({
    type: SEND_MESSAGE_FAILURE
});