import {
    ADD_EDIT_CALENDAR_EVENT,
    ADD_EDIT_CALENDAR_EVENT_FAILURE,
    ADD_EDIT_CALENDAR_EVENT_SUCCESS,
    DELETE_CALENDAR_EVENT,
    DELETE_CALENDAR_EVENT_FAILURE,
    DELETE_CALENDAR_EVENT_SUCCESS,
    GET_CALENDAR_EVENT,
    GET_CALENDAR_EVENT_FAILURE,
    GET_CALENDAR_EVENT_SUCCESS,
    GET_EVENT_DETAILS_BY_ID,
    GET_EVENT_DETAILS_BY_ID_FAILURE,
    GET_EVENT_DETAILS_BY_ID_SUCCESS, RESET_EVENT_DETAIL,
    RESPOND__EVENT,
    RESPOND__EVENT_SUCCESS,
    RESPOND_EVENT_FAILURE,
} from '../action/types';

const INIT_STATE = {
    loading: false,
    calendarEvent: null,
    eventDetails: null
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_CALENDAR_EVENT:
            return {...state, loading: true}
        case GET_CALENDAR_EVENT_SUCCESS:
            return {...state, loading: false, calendarEvent: action.payload}
        case GET_CALENDAR_EVENT_FAILURE:
            return {...state, loading: false, calendarEvent: action.payload}

        case DELETE_CALENDAR_EVENT:
            return {...state, loading: true}
        case DELETE_CALENDAR_EVENT_SUCCESS:
            return {...state, loading: false}
        case DELETE_CALENDAR_EVENT_FAILURE:
            return {...state, loading: false}

        case ADD_EDIT_CALENDAR_EVENT:
            return {...state, loading: true}
        case ADD_EDIT_CALENDAR_EVENT_SUCCESS:
            return {...state, loading: false}
        case ADD_EDIT_CALENDAR_EVENT_FAILURE:
            return {...state, loading: false}

        case RESET_EVENT_DETAIL:
            return {...state, eventDetails: null}

        case RESPOND__EVENT:
            return {...state, loading: true}
        case RESPOND__EVENT_SUCCESS:
            return {...state, loading: false}
        case RESPOND_EVENT_FAILURE:
            return {...state, loading: false}

        case GET_EVENT_DETAILS_BY_ID:
            return {...state, loading: true}
        case GET_EVENT_DETAILS_BY_ID_SUCCESS:
            return {...state, loading: false, eventDetails: action.payload}
        case GET_EVENT_DETAILS_BY_ID_FAILURE:
            return {...state, loading: false, eventDetails: action.payload}

        default:
            return state
    }
}
