import * as React from 'react';
import {useState} from 'react';

import {Trash} from 'react-bootstrap-icons';

import {DeleteConfirmDialog} from '../CommonComponents';

import {getUser, time_ago} from '../../utils/helper';
import {Fragment} from 'react';

const PublishBox = ({
	itemDetail,
	athletesList,
	usersList,
	form,
	handleChange,
	handleCheck,
	handleSubmit,
	handleDelete,
}) => {
	const [errors, setErrors] = useState({});
	const [valid, setValid] = useState({
		type: false,
		title: false,
		contents: false,
		raw_contents: false,
		pic: false,
		created_for: false,
		status: false,
		position: false,
		externals: false,
	});
	const [isDeleteOpen, setDeleteOpen] = useState(false);
	const itemId = itemDetail?.data?.documents?.id;
	console.log(itemDetail);
	return (
		<Fragment>
			<form className='bg-white p-4 f-16'>
				<h2 className='f-24 light-dark pb-2'>Publishing</h2>
				<strong className='montserrat-bold '>Created by</strong>
				<p className='light-dark f-16 pt-0 pb-2'>
					Super User{' '}
					{itemDetail && itemDetail.data
						? time_ago(itemDetail.data.documents.created_at)
						: '1 second ago '}
				</p>
				<strong className='montserrat-bold'>Last edited</strong>
				<p className='light-dark pt-0 pb-2'>
					Super User{' '}
					{itemDetail && itemDetail.data
						? time_ago(itemDetail.data.documents.updated_at)
						: '1 second ago '}
				</p>
				{JSON.parse(getUser()).is_admin && (
					<>
						<div className='pb-2'>
							<strong className='montserrat-bold'>Belongs to</strong>
							<br />
							{!itemId ? (
								<select
									className='select bg-transparent light-dark line-height-24 cursor-pointer'
									id='created_for'
									name='created_for'
									onChange={(e) => {
										handleChange(e);
										setValid({...valid, created_for: true});
										setErrors({...errors, created_for: false});
									}}
									value={form.created_for}>
									<option>Select athlete</option>
									{athletesList &&
										athletesList.length > 0 &&
										athletesList.map((itm) => (
											<option value={itm.id} key={itm.key}>
												{itm.first_name}
											</option>
										))}
								</select>
							) : (
								<p>
									{athletesList &&
										athletesList.length > 0 &&
										athletesList.find(
											(itm) => itm.id.toString() === form.created_for
										).first_name}
									{form.created_for}
								</p>
							)}
							<div className='invalid-feedbacks'>
								{errors && errors.created_for}
							</div>
						</div>
						<p className='pb-2'>
							<strong className='montserrat-bold'>Status</strong>
							<br />
							<select
								name='status'
								onChange={handleChange}
								value={form.status}
								className='select bg-transparent light-dark line-height-24 cursor-pointer'>
								<option value='draft' selected>
									Draft
								</option>
								<option value='published'>Published</option>
							</select>
						</p>
						<strong className='montserrat-bold'>Externals</strong>
						<br />
						{usersList && usersList.length > 0
							? usersList.map((itm) => (
									<span className='checkbox d-block'>
										<input
											key={itm.key}
											type='checkbox'
											value={itm.id}
											onChange={() => handleCheck(itm.id.toString())}
											checked={form.externals.includes(itm.id.toString())}
										/>
										&nbsp;
										{itm.first_name}
									</span>
							  ))
							: null}
					</>
				)}
			</form>
			{JSON.parse(getUser()).is_admin && itemId ? (
				<div className='line-height-48 border-top bg-white p-4 d-flex justify-content-between align-items-center delete-update-section f-16'>
					<span
						onClick={() => {
							setDeleteOpen(true);
						}}
						className='light-red cursor-pointer'>
						<Trash size={16} width='1em' height='1em' className='bi bi-trash' />
						Delete
					</span>
					<button
						onClick={handleSubmit}
						className='custom-btn text-decoration-none pl-4 pr-4  font-weight-bold border-0 rounded'>
						Update
					</button>
				</div>
			) : null}
			<DeleteConfirmDialog
				isDeleteOpen={isDeleteOpen}
				setDeleteOpen={setDeleteOpen}
				handleDelete={() => {
					handleDelete(itemId);
				}}
			/>
		</Fragment>
	);
};

export default PublishBox;
