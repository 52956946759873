import {
    GET_VISIONBOARD_LIST,
    GET_VISIONBOARD_LIST_SUCCESS,
    GET_VISIONBOARD_LIST_FAILURE,
    UPLOAD_VISIONBOARD,
    UPLOAD_VISIONBOARD_SUCCESS, UPLOAD_VISIONBOARD_FAILURE,
} from '../action/types'

const INIT_STATE = {
    loading: false,
    visionboardList: {},
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case UPLOAD_VISIONBOARD:
            return {...state, loading: true};
        case UPLOAD_VISIONBOARD_SUCCESS:
            return {...state, loading: false,};
        case UPLOAD_VISIONBOARD_FAILURE:
            return {...state, loading: false};
        case GET_VISIONBOARD_LIST:
            return { ...state, loading: true };
        case GET_VISIONBOARD_LIST_SUCCESS:
            return { ...state, loading: false, visionboardList: action.payload };
        case GET_VISIONBOARD_LIST_FAILURE:
            return { ...state, loading: false, visionboardList: action.payload };
        default:
            return state;
    }
};
